import React, { useState, useContext, useEffect } from 'react'
import cookie from 'react-cookies'
import NavBar from '../layout/NavBar'
import TopBar from '../layout/TopBar'
import PosTaskEffectivityView from './PosTaskEffectivityView'
import PosTaskEffectivityContext from './context/PosTaskEffectivityContext'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const PosTaskEffectivityApp = () => {
  const initPaginationData = () => {
    const searchParams = new URLSearchParams(window.location.search)
    let page = searchParams.get('page')
    let currentPage = 1
    if (page) {
      currentPage = Number(page)
    } else {
      searchParams.set('page', '1')
      let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString()
      window.history.pushState(null, '', newRelativePathQuery)
    }
    return {
      per_page: 25,
      current_page: currentPage,
      total_pages: 1,
      query: ''
    }
  }

  const [collapsed, setCollapsed] = useState(false)
  const [clientsData, setClientsData] = useState([])
  const [tasksData, setTasksData] = useState([])
  const [fetchDataStatus, setFetchDataStatus] = useState({})
  const [filterQuery, setFilterQuery] = useState(null)
  const [searchQuery, setSearchQuery] = useState(null)
  const [dateQuery, setDateQuery] = useState(null)
  const [pagination, setPagination] = useState(initPaginationData())
  const [title, setTitle] = useState('Efectividad en punto de venta')

  ////// Stops fetchs when page is changed
  const abortController = new AbortController()
  const abortCurrentFetchs = () => {
    abortController.abort();
  };
  window.addEventListener("beforeunload", abortCurrentFetchs);
  //////

  const handleToggleCollapse = (newCollapsed) => {
    setCollapsed(newCollapsed)
    localStorage.setItem('collapsedState', newCollapsed)
  }

  useEffect(() => {
    const collapsedFromStorage = localStorage.getItem('collapsedState') === 'true'
    setCollapsed(collapsedFromStorage)
  }, [])

  useEffect(() => {
    initPaginationData()
    async function fetchAll() {
      await fetchClientsFromServer()
    }
    fetchAll()
  }, [])

  useEffect(() => {
    updateURLPaginationData()
    async function fetchAll() {
      await fetchTasksFromServer()
    }
    fetchAll()
  }, [pagination.current_page, filterQuery, searchQuery, dateQuery])

  const handlePaginationClick = (event) => {
    // <a> = when pagination elements were clicked
    if (event.target.tagName === 'A') {
      handlePagination(event.target)
    } else if (event.target.tagName === 'svg') {
      handlePagination(event.target.parentNode)
    } else if (event.target.tagName === 'path') {
      handlePagination(event.target.parentNode.parentNode)
    }
  }

  const handlePagination = (target) => {
    let targetId = target.id
    switch (targetId) {
      case 'first-page':
        if (pagination.current_page !== 1) {
          setPagination({ ...pagination, current_page: 1 })
        }
        break
      case 'last-page':
        if (pagination.current_page !== pagination.total_pages) {
          setPagination({ ...pagination, current_page: pagination.total_pages })
        }
        break
      case 'previous-page':
        if (pagination.current_page !== 1) {
          setPagination({ ...pagination, current_page: pagination.current_page - 1 })
        }
        break
      case 'next-page':
        if (pagination.current_page !== pagination.total_pages) {
          setPagination({ ...pagination, current_page: pagination.current_page + 1 })
        }
        break
    }
  }

  const updateURLPaginationData = () => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('page', pagination.current_page)
    let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString()
    window.history.pushState(null, '', newRelativePathQuery)
  }

  const fetchClientsFromServer = async (loadingState = true) => {
    if (loadingState) {
      setFetchDataStatus({ ...fetchDataStatus, filters: true })
    }
    const response = await fetch(
      BACKEND_URL +
        '/pos_task_activities' +
        '?client_names_only=true&business_tenant_uid=' +
        cookie.load('business_tenant_uid'),
      {
        method: 'GET',
        signal: abortController.signal,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
    if (response.status === 401) {
      return console.error(response)
    }
    const data = await response.json()
     setClientsData(data['result'])
    setFetchDataStatus({ ...fetchDataStatus, filters: false })
  }

  const fetchTasksFromServer = async () => {
    const per_page = pagination.per_page
    const page = pagination.current_page
    const response = await fetch(
      BACKEND_URL +
        '/pos_task_activities' +
        '?page=' +
        page +
        '&per_page=' +
        per_page +
        (filterQuery || dateQuery || searchQuery ? '&filter_search=true' : '') +
        (filterQuery ? filterQuery : '') +
        (dateQuery ? dateQuery : '') +
        (searchQuery ? searchQuery : '') +
        '&business_tenant_uid=' +
        cookie.load('business_tenant_uid'),
      {
        method: 'GET',
        signal: abortController.signal,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
    if (response.status === 401) {
      return console.error(response)
    }
    const data = await response.json()

    setPagination({ ...pagination, total_pages: Math.ceil(data['count'] / pagination.per_page) })
    setTasksData(data['result'])
  }

  return (
    <div>
      <div>
        <div>
          <TopBar
            searchPlaceholder={''}
            userName={'soporte'}
            onToggleCollapse={handleToggleCollapse}
            collapsed={collapsed}
          />
        </div>
        <div>
          <PosTaskEffectivityContext.Provider
            value={{
              fetchDataStatus,
              clientsData,
              tasksData,
              pagination,
              setFilterQuery,
              setDateQuery,
              setSearchQuery,
              filterQuery,
              dateQuery,
              searchQuery,
              fetchClientsFromServer,
              fetchTasksFromServer,
              handlePaginationClick,
              title,
              setPagination  
            }}
          >
            <PosTaskEffectivityView collapsed={collapsed} />
          </PosTaskEffectivityContext.Provider>
        </div>
      </div>
      <div>
        <NavBar activeItem="Ejecución punto de venta" isEditing={false} collapsed={collapsed} />
      </div>
    </div>
  )
}
export default PosTaskEffectivityApp
