import React, { useState, useEffect } from 'react'
import { getTimestampFromDateTime, getCurrentDate } from '../helpers/formatDate'
import { capitalizeFirstLetterFrom, formatMoney, parseDocumentType } from '../helpers/utils'
import NavBar from '../layout/NavBar'
import TopBar from '../layout/TopBar'
import cookie from 'react-cookies'
import uuidv5 from 'uuid/v5'
import { CSVLink } from 'react-csv'
import {
  faArrowCircleDown,
  faExclamationCircle,
  faQuestionCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

function SettlementsDetails() {
  const tabs = ['Detalles', 'Documentos', 'Anulados']
  const [isFetching, setIsFetching] = useState(false)
  const [selectedTab, setSelectedTab] = useState('Detalles')
  const [error, setError] = useState(null)
  const [data, setData] = useState(null)
  const [collapsed, setCollapsed] = useState(false)
  const [searchInput, setSearchInput] = useState('')
  const [filteredDetails, setFilteredDetails] = useState([])

  const handleSearch = (event) => {
    setSearchInput(event.target.value)
  }

  const handleEnterSearch = (event) => {
    if (event.key === 'Enter') {
      filterDetails()
    }
  }

  const filterDetails = () => {
    let searchQuery = searchInput.trim().toLowerCase()

    let filtered = []

    if (selectedTab === 'Documentos') {
      filtered = data?.details.filter(
        (detail) =>
          detail.child_document_reference.includes(searchQuery) ||
          detail.charge_uid.includes(searchQuery)
      )
    } else if (selectedTab === 'Anulados') {
      if (searchQuery === 'factura') {
        searchQuery = 'F'
      } else if (searchQuery.includes('credito') || searchQuery === 'nota de credito') {
        searchQuery = 'C'
      }

      filtered = data?.details.filter(
        (detail) =>
          detail.child_document_reference.includes(searchQuery) ||
          detail.child_document_type === searchQuery
      )
    }

    setFilteredDetails(filtered)
  }

  const resetFilters = () => {
    setSearchInput('')
    setFilteredDetails(data?.details || [])
  }

  const fetchDataFromServer = async () => {
    setIsFetching(true)
    const response = await fetch(
      BACKEND_URL +
        '/settlements/' +
        window.location.href.split('/')[window.location.href.split('/').length - 1],
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
    if (response.status != 200) {
      setData(null)
      setError('No se encontró la liquidación')
    } else {
      let json = await response.json()
      setData(json)
    }
    setIsFetching(false)
  }

  const getListTabStyles = (tab) => {
    return selectedTab == tab ? 'active' : ''
  }

  const getAnchorTabStyles = (tab) => {
    return selectedTab == tab ? 'cursor-pointer' : 'inactive-tab cursor-pointer'
  }

  const handleChargeClick = (uid) => {
    let businessUid = cookie.load('business_tenant_uid')
    let uuid = uuidv5(`${businessUid}~${uid}`, 'd90e460b-8136-48a0-8d50-760d2fec9a39')
    let url = '/cobros/' + uuid
    window.open(url, '_blank')
  }

  useEffect(() => {
    fetchDataFromServer()
  }, [])

  useEffect(() => {
    setFilteredDetails(data?.details || [])
  }, [data])

  const handleToggleCollapse = (newCollapsed) => {
    setCollapsed(newCollapsed)
    localStorage.setItem('collapsedState', newCollapsed)
  }

  useEffect(() => {
    const collapsedFromStorage = localStorage.getItem('collapsedState') === 'true'
    setCollapsed(collapsedFromStorage)
  }, [])

  function formatDate(dateyears) {
    const date = new Date(dateyears)
    return date.toLocaleDateString('es', { day: '2-digit', month: '2-digit', year: 'numeric' })
  }

  function formatTime(time) {
    const date = new Date(time)
    return date.toLocaleTimeString('es', { hour: '2-digit', minute: '2-digit', hour12: true })
  }

  const buildCsvFile = () => {
    let rows = []
    rows.push(['Reporte:', 'Detalles de Liquidación'])
    rows.push(['Número de liquidación:', data.uid])
    rows.push(['Código de usuario:', ' ' + data.user_uid])
    rows.push(['Usuario:', data.user_full_name])
    rows.push(['Fecha de creación:', getTimestampFromDateTime(data.collected_at)])
    rows.push([''])
    rows.push(['RESUMEN TOTAL'])
    rows.push([''])
    rows.push(['Efectivo:', formatMoney(data.cash_amount)])
    rows.push(['Transferencia:', formatMoney(data.transference_amount)])
    rows.push(['Pago digital:', formatMoney(data.payment_digital_amount)])
    rows.push(['Deposito bancario:', formatMoney(data.deposit_amount)])
    rows.push(['Cheque:', formatMoney(data.cheque_amount)])
    rows.push(['Tarjeta de credito:', formatMoney(data.credit_card_amount)])
    rows.push(['Vale digital:', formatMoney(data.vale_digital_amount)])
    rows.push(['Pago policia:', formatMoney(data.payment_amount)])
    rows.push(['TOTAL COBRADO:', formatMoney(data.total_amount)])
    rows.push([''])
    if (!data.charge_uid || data.charge_uid.length === 0) {
      rows.push(['Total entregas a crédito:', formatMoney(data.total_amount)])
      rows.push([''])
    }
    const headersReceived = [
      'FECHA',
      'HORA',
      'CODIGO',
      'CLIENTE',
      'NUMERO DE DOCUMENTO',
      'TIPO DE DOCUMENTO',
      'NUMERO DE COBRO',
      'TIPO DE PAGO',
      'TOTAL'
    ]
    const headersCancelled = [
      'FECHA',
      'HORA',
      'CODIGO',
      'CLIENTE',
      'NUMERO DE DOCUMENTO',
      'TIPO DE DOCUMENTO',
      'ESTATUS',
      'TOTAL'
    ]

    const mappingReceived = {
      FECHA: 'date_of_charge',
      HORA: 'date_of_charge',
      CODIGO: 'client_uid',
      CLIENTE: 'client_name',
      'NUMERO DE DOCUMENTO': 'child_document_reference',
      'TIPO DE DOCUMENTO': 'child_document_type',
      'NUMERO DE COBRO': 'charge_uid',
      'TIPO DE PAGO': 'charge_uid',
      TOTAL: 'child_document_total'
    }
    const mappingCancelled = {
      FECHA: 'date_of_charge',
      HORA: 'date_of_charge',
      CODIGO: 'client_uid',
      CLIENTE: 'client_name',
      'NUMERO DE DOCUMENTO': 'child_document_reference',
      'TIPO DE DOCUMENTO': 'child_document_type',
      ESTATUS: 'charge_status',
      TOTAL: 'child_document_total'
    }

    let cancelledDetails = [
      ['DETALLES DE DOCUMENTOS ANULADOS ASOCIADOS A ESTA LIQUIDACIÓN:'],
      [''],
      headersCancelled
    ]
    let receivedDetails = [
      ['DETALLES DE DOCUMENTOS ASOCIADOS A ESTA LIQUIDACIÓN:'],
      [''],
      headersReceived
    ]

    data.details.forEach((detail) => {
      let detailArrayCancelled = headersCancelled.map((header) => {
        if (header === 'FECHA') {
          return formatDate(detail[mappingCancelled[header]])
        } else if (header === 'HORA') {
          return formatTime(detail[mappingCancelled[header]])
        } else if (header === 'TIPO DE DOCUMENTO') {
          return detail[mappingCancelled[header]] === 'F'
            ? 'Factura'
            : detail[mappingCancelled[header]] === 'C'
            ? 'Nota de crédito'
            : detail[mappingCancelled[header]]
        } else if (header === 'ESTATUS') {
          return 'Anulado'
        } else {
          return detail[mappingCancelled[header]]
        }
      })
      let detailArrayReceived = headersReceived.map((header) => {
        if (header === 'FECHA') {
          return formatDate(detail[mappingCancelled[header]])
        } else if (header === 'HORA') {
          return formatTime(detail[mappingCancelled[header]])
        } else if (header === 'TIPO DE PAGO') {
          return detail[mappingReceived[header]] ? 'Contado' : 'Crédito'
        } else if (header === 'TIPO DE DOCUMENTO') {
          return detail[mappingReceived[header]] === 'F'
            ? 'Factura'
            : detail[mappingReceived[header]] === 'C'
            ? 'Nota de crédito'
            : detail[mappingReceived[header]]
        } else {
          return detail[mappingReceived[header]]
        }
      })

      if (detail.charge_status === 'cancelled') {
        cancelledDetails.push(detailArrayCancelled)
      } else if (detail.charge_status === 'received') {
        receivedDetails.push(detailArrayReceived)
      }
    })

    rows = rows.concat(receivedDetails)
    rows.push([''])
    rows = rows.concat(cancelledDetails)

    return rows
  }

  return (
    <div>
      {isFetching === false && data ? (
        <div>
          <div>
            <div>
              <div>
                <TopBar
                  searchPlaceholder={''}
                  onToggleCollapse={handleToggleCollapse}
                  collapsed={collapsed}
                />
              </div>
              <div>
                {error ? (
                  <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
                    <div className="main-show-header">
                      <p>{error}</p>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
                      <div className="main-show-header">
                        <h2>Liquidación: {data?.uid}</h2>
                      </div>
                      <div className="index-table">
                        <div className="show-area">
                          <div>
                            <div>
                              <div id="tabs">
                                <div className="mb-1">
                                  <ul className="nav nav-tabs">
                                    {tabs.map((value, index) => (
                                      <li
                                        key={index}
                                        onClick={() => setSelectedTab(value)}
                                        id="details_tab"
                                        className={getListTabStyles(value)}
                                      >
                                        <a className={getAnchorTabStyles(value)}>{value}</a>
                                      </li>
                                    ))}
                                    <div className="range-dates">
                                      <CSVLink
                                        className="btn btn-default download-report-button-settlements"
                                        data={buildCsvFile()}
                                        filename={`DetallesDeLiquidacion_${data?.user_full_name}_${
                                          data?.business_uid
                                        }_${getCurrentDate()}.csv`}
                                      >
                                        <FontAwesomeIcon icon={faArrowCircleDown} />
                                        Descargar Liquidación
                                      </CSVLink>
                                    </div>
                                  </ul>
                                </div>
                              </div>
                              <div>
                                <div className="container-fluid">
                                  <div className="row">
                                    <div className="row show-product-header">
                                      <div className="col-sm-6">
                                        {selectedTab === 'Detalles' && (
                                          <h3 className="fs-18">Detalles de liquidación:</h3>
                                        )}
                                        {selectedTab === 'Documentos' && (
                                          <h3 className="fs-18">
                                            Detalles de documentos asociados a esta liquidación:{' '}
                                          </h3>
                                        )}
                                        {selectedTab === 'Anulados' && (
                                          <h3 className="fs-18">
                                            Detalles de documentos anulados asociados a esta
                                            liquidación:{' '}
                                          </h3>
                                        )}
                                      </div>
                                    </div>
                                    <div>
                                      <hr />
                                      <div>
                                        <table cellSpacing="10">
                                          <tbody>
                                            <tr>
                                              <td>
                                                <div className="mr-20 mb-10">
                                                  <b>Fecha de creación:</b>
                                                </div>
                                              </td>
                                              <td>
                                                <div className="mb-10">
                                                  {getTimestampFromDateTime(data?.collected_at)}
                                                </div>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <div className="mr-20 mb-10">
                                                  <b>Código de usuario:</b>
                                                </div>
                                              </td>
                                              <td>
                                                <div className="mb-10">{data?.user_uid}</div>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <div className="mr-20 mb-10">
                                                  <b>Usuario:</b>
                                                </div>
                                              </td>
                                              <td>
                                                <div className="mb-10">{data?.user_full_name}</div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>

                                        <hr />
                                        {selectedTab === 'Detalles' && (
                                          <>
                                            <div className="totals-container-left">
                                              <div className="totals-card-left">
                                                <div className="totals-line">
                                                  <div>
                                                    <b>RESUMEN TOTAL</b>
                                                  </div>
                                                  <div></div>
                                                </div>
                                                {data?.is_cash_enabled && (
                                                  <div className="totals-line">
                                                    <div>Efectivo:</div>
                                                    <div>{formatMoney(data?.cash_amount)}</div>
                                                  </div>
                                                )}
                                                {data?.is_transfer_enabled && (
                                                  <div className="totals-line">
                                                    <div>Transferencia:</div>
                                                    <div>
                                                      {formatMoney(data.transference_amount)}
                                                    </div>
                                                  </div>
                                                )}
                                                {data?.is_digital_enabled && (
                                                  <div className="totals-line">
                                                    <div>Pago Digital:</div>
                                                    <div>
                                                      {formatMoney(data.payment_digital_amount)}
                                                    </div>
                                                  </div>
                                                )}

                                                {data.is_deposit_enabled && (
                                                  <div className="totals-line">
                                                    <div>Depósito bancario:</div>
                                                    <div>{formatMoney(data.deposit_amount)}</div>
                                                  </div>
                                                )}
                                                {data.is_cheque_enabled && (
                                                  <div className="totals-line">
                                                    <div>Cheque:</div>
                                                    <div>{formatMoney(data.cheque_amount)}</div>
                                                  </div>
                                                )}
                                                {data.is_credit_card_enabled && (
                                                  <div className="totals-line">
                                                    <div>Tarjeta de crédito:</div>
                                                    <div>
                                                      {formatMoney(data.credit_card_amount)}
                                                    </div>
                                                  </div>
                                                )}

                                                {data.is_vale_enabled && (
                                                  <div className="totals-line">
                                                    <div>Vale digital:</div>
                                                    <div>
                                                      {formatMoney(data.vale_digital_amount)}
                                                    </div>
                                                  </div>
                                                )}
                                                {data.is_push_money_enabled && (
                                                  <div className="totals-line">
                                                    <div>Viñetas:</div>
                                                    <div>{formatMoney(data.push_money_amount)}</div>
                                                  </div>
                                                )}
                                                {data.is_police_enabled && (
                                                  <div
                                                    className="totals-line"
                                                    style={{ color: '#F15D5D' }}
                                                  >
                                                    <div>Pago policía:</div>
                                                    <div>-{formatMoney(data.payment_amount)}</div>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                            <div className="totals-container-left">
                                              <div className="totals-card-left">
                                                <div className="totals-line">
                                                  <div>
                                                    <h4>
                                                      <b
                                                        style={{
                                                          whiteSpace: 'nowrap',
                                                          color: '#50739A'
                                                        }}
                                                      >
                                                        TOTAL COBRADO:{' '}
                                                      </b>
                                                    </h4>
                                                  </div>
                                                  <div>
                                                    <h4>
                                                      <b
                                                        style={{
                                                          whiteSpace: 'nowrap',
                                                          color: '#50739A'
                                                        }}
                                                      >
                                                        {formatMoney(data.total_amount)}
                                                      </b>
                                                    </h4>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            {data.delivery_credit_amount &&
                                              data.delivery_credit_amount > 0 && (
                                                <div className="totals-container-left">
                                                  <div className="totals-card-left">
                                                    <div className="totals-line">
                                                      <div>
                                                        <b
                                                          style={{
                                                            whiteSpace: 'nowrap',
                                                            color: '#50739A'
                                                          }}
                                                        >
                                                          Total entregas a crédito:{' '}
                                                        </b>
                                                      </div>
                                                      <div>
                                                        {formatMoney(data.delivery_credit_amount)}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                          </>
                                        )}

                                        {selectedTab === 'Documentos' && (
                                          <div>
                                            <div className="title-and-search-containter">
                                              <h4 className="">Listado de liquidaciones:</h4>

                                              <div className="search-input-containter mb-20">
                                                <input
                                                  value={searchInput}
                                                  onChange={handleSearch}
                                                  onKeyDown={handleEnterSearch}
                                                  className="round-search-input"
                                                  placeholder="Buscar por número de documento o de cobro"
                                                />
                                                {searchInput === '' && (
                                                  <span className="glyphicon glyphicon-search form-control-feedback" />
                                                )}
                                                {searchInput && (
                                                  <div
                                                    title="Limpiar texto"
                                                    onClick={resetFilters}
                                                    className="round-search-input-x"
                                                  >
                                                    X
                                                  </div>
                                                )}
                                              </div>
                                            </div>

                                            <table className="table table-hover mb-0">
                                              <thead>
                                                <tr>
                                                  <th style={{ verticalAlign: 'middle' }}>
                                                    <span className="th-header-text">
                                                      FECHA Y HORA
                                                    </span>
                                                  </th>
                                                  <th style={{ verticalAlign: 'middle' }}>
                                                    <span className="th-header-text">CÓDIGO</span>
                                                  </th>
                                                  <th style={{ verticalAlign: 'middle' }}>
                                                    <span className="th-header-text">CLIENTE</span>
                                                  </th>
                                                  <th style={{ verticalAlign: 'middle' }}>
                                                    <span className="th-header-text">
                                                      NÚMERO DE DOCUMENTO
                                                    </span>
                                                  </th>
                                                  <th style={{ verticalAlign: 'middle' }}>
                                                    <span className="th-header-text">
                                                      TIPO DE DOCUMENTO
                                                    </span>
                                                  </th>
                                                  <th style={{ verticalAlign: 'middle' }}>
                                                    <span className="th-header-text">
                                                      NÚMERO DE COBRO
                                                    </span>
                                                  </th>
                                                  <th style={{ verticalAlign: 'middle' }}>
                                                    <span className="th-header-text">
                                                      TIPO DE PAGO
                                                    </span>
                                                  </th>
                                                  <th style={{ verticalAlign: 'middle' }}>
                                                    <span className="th-header-text">TOTAL</span>
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {filteredDetails
                                                  .reverse()
                                                  .filter(
                                                    (charge) => charge.charge_status !== 'cancelled'
                                                  ).length > 0 ? (
                                                  filteredDetails
                                                    .reverse()
                                                    .filter(function (charge) {
                                                      return charge.charge_status !== 'cancelled'
                                                    })
                                                    .map((model, index) => (
                                                      <tr key={index}>
                                                        <td className="td-text-limited">
                                                          {getTimestampFromDateTime(
                                                            model?.date_of_charge
                                                          )}
                                                        </td>
                                                        <td className="td-text-limited">
                                                          {model?.client_uid}
                                                        </td>

                                                        <td
                                                          className="td-text-limited"
                                                          style={{ maxWidth: '350px' }}
                                                        >
                                                          <div className="text-limit-2-lines ">
                                                            {model?.client_name}
                                                          </div>
                                                        </td>
                                                        <td className="td-text-limited">
                                                          {model?.child_document_reference}
                                                        </td>
                                                        <td className="td-text-limited">
                                                          {parseDocumentType(
                                                            model?.child_document_type
                                                          )}
                                                        </td>
                                                        <td className="td-text-limited">
                                                          {model?.charge_uid ? (
                                                            <span
                                                              onClick={() =>
                                                                handleChargeClick(model?.charge_uid)
                                                              }
                                                              className="ubiqua-link"
                                                            >
                                                              {model?.charge_uid}
                                                            </span>
                                                          ) : (
                                                            <>-</>
                                                          )}
                                                        </td>
                                                        <td className="td-text-limited">
                                                          {model?.charge_uid ? (
                                                            <>Contado</>
                                                          ) : (
                                                            <>Crédito</>
                                                          )}
                                                        </td>
                                                        <td className="td-text-limited">
                                                          {formatMoney(
                                                            Number(model?.child_document_total)
                                                          )}
                                                        </td>
                                                      </tr>
                                                    ))
                                                ) : (
                                                  <tr>
                                                    <td colSpan="7">No hay resultados</td>
                                                  </tr>
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        )}

                                        {selectedTab === 'Anulados' && (
                                          <div>
                                            <div className="title-and-search-containter">
                                              <h4 className="">
                                                Detalles de liquidaciones anuladas:
                                              </h4>
                                              <div className="search-input-containter mb-20">
                                                <input
                                                  value={searchInput}
                                                  onChange={handleSearch}
                                                  onKeyDown={handleEnterSearch}
                                                  className="round-search-input"
                                                  placeholder="Buscar anulados por número de documento o tipo"
                                                />
                                                {searchInput === '' && (
                                                  <span className="glyphicon glyphicon-search form-control-feedback" />
                                                )}
                                                {searchInput && (
                                                  <div
                                                    title="Limpiar texto"
                                                    onClick={resetFilters}
                                                    className="round-search-input-x"
                                                  >
                                                    X
                                                  </div>
                                                )}
                                              </div>
                                            </div>

                                            <table className="table table-hover mb-0">
                                              <thead>
                                                <tr>
                                                  <th style={{ verticalAlign: 'middle' }}>
                                                    <span className="th-header-text ">
                                                      FECHA Y HORA
                                                    </span>
                                                  </th>
                                                  <th style={{ verticalAlign: 'middle' }}>
                                                    <span className="th-header-text">CÓDIGO</span>
                                                  </th>
                                                  <th style={{ verticalAlign: 'middle' }}>
                                                    <span className="th-header-text">CLIENTE</span>
                                                  </th>
                                                  <th style={{ verticalAlign: 'middle' }}>
                                                    <span className="th-header-text">
                                                      NÚMERO DE DOCUMENTO
                                                    </span>
                                                  </th>
                                                  <th style={{ verticalAlign: 'middle' }}>
                                                    <span className="th-header-text">
                                                      TIPO DE DOCUMENTO
                                                    </span>
                                                  </th>
                                                  <th style={{ verticalAlign: 'middle' }}>
                                                    <span className="th-header-text">ESTATUS</span>
                                                  </th>
                                                  <th style={{ verticalAlign: 'middle' }}>
                                                    <span className="th-header-text">TOTAL</span>
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {filteredDetails
                                                  .reverse()
                                                  .filter(
                                                    (charge) => charge.charge_status === 'cancelled'
                                                  ).length > 0 ? (
                                                  filteredDetails
                                                    .reverse()
                                                    .filter(function (charge) {
                                                      return charge.charge_status === 'cancelled'
                                                    })
                                                    .map((model, index) => (
                                                      <tr key={index}>
                                                        <td className="td-text-limited">
                                                          {getTimestampFromDateTime(
                                                            model?.date_of_charge
                                                          )}
                                                        </td>
                                                        <td className="td-text-limited">
                                                          {model?.client_uid}
                                                        </td>
                                                        <td className="td-text-limited">
                                                          {model?.client_name}
                                                        </td>
                                                        <td className="td-text-limited">
                                                          {model?.child_document_reference}
                                                        </td>
                                                        <td className="td-text-limited">
                                                          {parseDocumentType(
                                                            model?.child_document_type
                                                          )}
                                                        </td>
                                                        <td className="td-text-limited">Anulado</td>
                                                        <td className="td-text-limited">
                                                          {formatMoney(
                                                            Number(model?.child_document_total)
                                                          )}
                                                        </td>
                                                      </tr>
                                                    ))
                                                ) : (
                                                  <tr>
                                                    <td colSpan="4">No hay resultados</td>
                                                  </tr>
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div>
              <NavBar activeItem="Cobro" isEditing={false} collapsed={collapsed} />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <TopBar
            searchPlaceholder={''}
            onToggleCollapse={handleToggleCollapse}
            collapsed={collapsed}
          />
          <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
            <div className="show-title">
              <p>Obteniendo datos ....</p>
            </div>
          </div>
          <div>
            <NavBar activeItem="Liquidaciones" isEditing={false} collapsed={collapsed} />
          </div>
        </div>
      )}
    </div>
  )
}
export default SettlementsDetails
