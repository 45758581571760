import React, { useEffect, useState } from 'react'
import cookie from 'react-cookies'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export default function TableauIndexView(props) {
  const [tokenData, setTokenData] = useState({})
  const [collapsed, setCollapsed] = useState(props.collapsed);

  const fetchTableauData = async () => {
    const response = await fetch(
      BACKEND_URL +
        '/admin/businesses' +
        '?business_tenant_uid=' +
        cookie.load('business_tenant_uid') +
        '&tableau_auth=true',
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
    if (response.status === 401) {
      return console.error(response)
    }

    const data = await response.json()
    setTokenData(data.result)
  }

  useEffect(() => {
    fetchTableauData()
  }, [])

  useEffect(() => {
    setCollapsed(props.collapsed);
  }, [props.collapsed]);


  return (
    <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
      <div className="index-header">
        <h2 className="mb-20 float-unset">Dashboard</h2>
      </div>
      <div className="index-table">
        <div className="show-area">
          {tokenData && tokenData?.token && tokenData?.tableau_dashboard_url && (
            <>
              <tableau-viz
                hide-tabs
                style={{ minHeight: '1000px' }}
                id="tableauViz"
                src={tokenData.tableau_dashboard_url}
                token={tokenData.token}
              ></tableau-viz>
              {/* <div className="horizontalBox-container">
                <div className="hotizontalBox"></div>
              </div> */}
            </>
          )}
        </div>
      </div>
    </div>
  )
}
