import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowCircleDown,
  faExclamationCircle,
  faQuestionCircle,
  faSortDown
} from '@fortawesome/free-solid-svg-icons'
import cookie from 'react-cookies'
import { CSVLink } from 'react-csv'
import { Table } from 'react-bootstrap'
import moment from 'moment'
import { formatMoney, safePercent } from '../../helpers/utils'
import OverviewCard from '../components/OverviewCard'
import GeoCard from '../components/GeoCard'
import AmountCard from '../components/AmountCard'
import ReportMotiveCard from '../components/ReportMotiveCard'
import ReportPayment from '../components/ReportPayment'
import { DicRoles, ShowTabs, DicRolesTab, RoleLabels } from '../../helpers/reportConstants'
import SimpleCard from '../components/SimpleCard'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const EffectivenessIndexView = (props) => {
  const [headers] = useState([
    'Código',
    'Vendedor',
    'Grupo',
    'Dia de primera visita',
    'Hora de primera visita',
    'Dia de ultima visita',
    'Hora de ultima actividad',
    'Meta de visitas',
    'Clientes visitados',
    'Visita/Meta',
    'Clientes con pedidos',
    'Venta/Visita',
    'Devolución Bruta',
    'Total Pedidos'
  ])

  //
  //   label: Nombre del campo
  //   show: Array que define en qué pestañas mostrar el campo
  //   decription: Descripción del campo
  //   fieldName: array de objetos con el nombre de la propiedad a leer de dat
  //   format: custom function to format the value
  //

  const uiHeaders = [
    {
      id: 'code',
      label: 'Código',
      show: [ShowTabs.All],
      description: 'Número de identificación del usuario',
      fieldNames: [
        {
          [ShowTabs.All]: 'user_db_ref'
        }
      ]
    },
    {
      id: 'name',
      label: 'Nombre',
      show: [ShowTabs.All],
      description: 'Nombre del usuario',
      fieldNames: [
        {
          [ShowTabs.All]: 'user_name'
        }
      ]
    },
    {
      id: 'role',
      label: 'Rol de usuario',
      show: [
        /** ShowTabs.All */
      ],
      description: 'Son los roles activos en este momento para el usuario',
      fieldNames: [
        {
          [ShowTabs.All]: 'user_active_roles'
        }
      ],
      format: (value) => parseUserRoles(value)
    },
    {
      id: 'group',
      label: 'Grupo',
      show: [ShowTabs.Pedidos, ShowTabs.Devoluciones],
      description: 'Grupo al que pertenecen los usuarios',
      fieldNames: [
        {
          [ShowTabs.All]: 'user_group'
        }
      ]
    },
    {
      id: 'last_visit',
      label: 'Hora de última actividad',
      show: [ShowTabs.All],
      description: 'Hora en la que el usuario realizó su última actividad',
      fieldNames: [
        {
          [ShowTabs.Resumen]: 'last_visit',
          [ShowTabs.Pedidos]: 'order_last_visit',
          [ShowTabs.Devoluciones]: 'refund_last_visit',
          [ShowTabs.Cobros]: 'charge_last_visit',
          [ShowTabs.Entregas]: 'delivery_last_visit',
          [ShowTabs.Pos]: 'pos_task_activity_last_visit'
        }
      ],
      format: (value) => {
        return value != '-' ? moment(value).format('hh:mm A') : value
      }
    },
    {
      id: 'deliveried_docs',
      label: 'Documentos entregados',
      show: [ShowTabs.Entregas],
      description:
        'Es el total de documentos entregados versus el total de documentos por entregar',
      fieldNames: [
        {
          [ShowTabs.Entregas]: 'delivery_documents_closed_count',
          [ShowTabs.Cobros]: 'charge_documents_count'
        },
        {
          [ShowTabs.Entregas]: 'charge_documents_count',
          [ShowTabs.Cobros]: 'charge_documents_total_count'
        }
      ]
    },
    {
      id: 'visited_clients',
      label: 'Clientes visitados',
      show: [ShowTabs.All],
      description: 'Es el total de clientes visitados versus el total de clientes por visitar',
      fieldNames: [
        {
          [ShowTabs.Resumen]: 'total_visited_clients_count',
          [ShowTabs.Pedidos]: 'total_visited_clients_count',
          [ShowTabs.Devoluciones]: 'total_visited_clients_count',
          [ShowTabs.Cobros]: 'charge_visited_clients_count',
          [ShowTabs.Entregas]: 'delivery_visited_clients_count',
          [ShowTabs.Pos]: 'pos_task_activity_visited_clients_count'
        },
        {
          [ShowTabs.Resumen]: 'total_clients_count',
          [ShowTabs.Pedidos]: 'order_total_clients_count',
          [ShowTabs.Devoluciones]: 'order_total_clients_count',
          [ShowTabs.Cobros]: 'charge_total_clients_count',
          [ShowTabs.Entregas]: 'delivery_total_clients_count',
          [ShowTabs.Pos]: 'pos_task_activity_total_clients_count'
        }
      ]
    },
    {
      id: 'visited_clients_percent',
      label: '% de visitados',
      show: [ShowTabs.All],
      description: 'Es el porcentaje calculado entre clientes visitados y la meta de visitas',
      fieldNames: [
        {
          [ShowTabs.Resumen]: 'total_percent_visited',
          [ShowTabs.Pedidos]: 'total_percent_visited',
          [ShowTabs.Devoluciones]: 'total_percent_visited',
          [ShowTabs.Cobros]: 'charge_percent_visited',
          [ShowTabs.Entregas]: 'delivery_percent_visited',
          [ShowTabs.Pos]: 'pos_task_activity_percent_visited'
        }
      ],
      format: (value) => `${value ? value.toFixed(0) : value}%`,
      formatCsv: (value) => `${value ? value.toFixed(0) : value}%`
    },
    {
      id: 'effective_visited_clients',
      label: 'Visitas efectivas',
      show: [ShowTabs.All],
      description: 'Son las transacciones concretadas en base a los clientes visitados',
      fieldNames: [
        {
          [ShowTabs.Resumen]: 'total_effective_clients_count',
          [ShowTabs.Pedidos]: 'order_effective_clients_count',
          [ShowTabs.Devoluciones]: 'refund_effective_clients_count',
          [ShowTabs.Cobros]: 'charge_effective_clients_count',
          [ShowTabs.Entregas]: 'delivery_effective_clients_count',
          [ShowTabs.Pos]: 'pos_task_activity_effective_clients_count'
        },
        {
          [ShowTabs.All]: 'total_visited_clients_count'
        }
      ],
      format: (effectiveVisits, totalVisited) => {
        const effectiveNumeric = parseInt(effectiveVisits, 10)
        const totalVisitedNumeric = parseInt(totalVisited, 10)
        const percent = safePercent(effectiveNumeric, totalVisitedNumeric)
        return `${percent}%`
      }
    },
    {
      id: 'effective_visited_clients_delivery',
      label: '% efectividad visita/entregas',
      show: [ShowTabs.Entregas],
      description: 'Es el porcentaje de las visitas versus las entregas realizadas',
      fieldNames: [
        {
          [ShowTabs.Entregas]: 'delivery_percent_effectiveness'
        }
      ],
      format: (value) => `${value ? value.toFixed(0) : value}%`
    },
    {
      id: 'effective_visited_clients_charge',
      label: '% efectividad visita/cobros',
      show: [ShowTabs.Cobros],
      description:
        'Es la efectividad del usuario porcentualmente en base a las órdenes concretadas y los clientes visitados',
      fieldNames: [
        {
          [ShowTabs.Cobros]: 'charge_percent_effectiveness'
        }
      ],
      format: (value) => `${value ? value.toFixed(0) : value}%`
    },
    {
      id: 'geo',
      label: 'Dentro de geocerca',
      show: [ShowTabs.All],
      description: 'Indica si el usuario estuvo dentro de la geocerca durante la actividad',
      fieldNames: [
        {
          [ShowTabs.Resumen]: 'total_location_effective_clients_count',
          [ShowTabs.Pedidos]: 'order_location_effective_count',
          [ShowTabs.Devoluciones]: 'refund_location_effective_count',
          [ShowTabs.Cobros]: 'charge_location_effective_count',
          [ShowTabs.Entregas]: 'delivery_location_effective_count',
          [ShowTabs.Pos]: 'pos_task_activity_location_effective_count'
        },
        {
          [ShowTabs.Resumen]: 'total_location_available_count',
          [ShowTabs.Pedidos]: 'order_location_available_count',
          [ShowTabs.Devoluciones]: 'refund_location_available_count',
          [ShowTabs.Cobros]: 'charge_location_available_count',
          [ShowTabs.Entregas]: 'delivery_location_available_count',
          [ShowTabs.Pos]: 'pos_task_activity_location_available_count'
        }
      ]
    },
    {
      id: 'in_site_task',
      label: 'Tareas en sitio',
      show: [ShowTabs.Resumen, ShowTabs.Pos],
      applyForRolesOnly: [ShowTabs.Pos],
      description: 'Es el progreso actual: Tareas realizadas vs. tareas restantes',
      fieldNames: [
        {
          [ShowTabs.Resumen]: 'pos_task_activity_done_task_codes_count',
          [ShowTabs.Pos]: 'pos_task_activity_done_task_codes_count'
        },
        {
          [ShowTabs.Resumen]: 'pos_task_activity_total_clients_count',
          [ShowTabs.Pos]: 'pos_task_activity_total_clients_count'
        }
      ]
    },
    {
      id: 'done_settlement',
      label: 'Liquidaciones realizadas',
      show: [],
      description: 'Sin descripción',
      fieldNames: [
        {
          [ShowTabs.All]: 'settlement_done_settlement'
        }
      ]
    },
    {
      id: 'anulados',
      label: 'Anulados',
      show: [ShowTabs.Cobros],
      description: 'Es la sumatoria de los cobros anulados hechas por el usuario',
      fieldNames: [
        {
          [ShowTabs.Cobros]: 'charge_cancelled_count'
        }
      ]
    },
    {
      id: 'total_charge',
      label: 'Total de monto',
      show: [ShowTabs.Cobros],
      description: 'Es la sumatoria del total de cobros hechos por el usuario',
      fieldNames: [
        {
          [ShowTabs.All]: 'total_charge'
        }
      ],
      format: (value) => formatMoney(value),
      formatCsv: (value) => (value ? formatMoney(Number(value).toFixed(2)) : 0.0)
    },
    {
      id: 'total_refund',
      label: 'Total de devoluciones',
      show: [ShowTabs.Resumen, ShowTabs.Devoluciones],
      description: 'Es la sumatoria del total de devoluciones hechas por el usuario',
      fieldNames: [
        {
          [ShowTabs.All]: 'total_refund'
        }
      ],
      format: (value) => formatMoney(value),
      formatCsv: (value) => (value ? formatMoney(Number(value).toFixed(2)) : 0.0)
    },
    {
      id: 'total_rejected',
      label: 'Total de rechazos',
      show: [ShowTabs.Entregas],
      description: 'Es la sumatoria del total de rechazos hechos por el usuario',
      fieldNames: [
        {
          [ShowTabs.All]: 'total_delivery_rejected'
        }
      ],
      format: (value) => formatMoney(value),
      formatCsv: (value) => (value ? formatMoney(Number(value).toFixed(2)) : 0.0)
    },
    {
      id: 'total_delivery',
      label: 'Total de entregas',
      show: [ShowTabs.Resumen, ShowTabs.Entregas],
      description: 'Es la sumatoria del total de entregas hechas por el usuario',
      fieldNames: [
        {
          [ShowTabs.All]: 'total_delivery'
        }
      ],
      format: (value) => formatMoney(value),
      formatCsv: (value) => (value ? formatMoney(Number(value).toFixed(2)) : 0.0)
    },
    {
      id: 'total_order',
      label: 'Total Pedidos',
      show: [ShowTabs.Resumen, ShowTabs.Pedidos],
      description: 'Es la sumatoria del total de órdenes hechas por el usuario',
      fieldNames: [
        {
          [ShowTabs.All]: 'total_order'
        }
      ],
      format: (value) => formatMoney(value),
      formatCsv: (value) => (value ? formatMoney(Number(value).toFixed(2)) : 0.0)
    }
  ]

  const getStartDate = () => {
    if (window.location.href.indexOf('start_date') >= 0) {
      const startDay = window.location.href.split('start_date=')
      return startDay[startDay.length - 1].split('&')[0]
    } else {
      return getCurrentDate()
    }
  }

  const getEndDate = () => {
    if (window.location.href.indexOf('end_date') >= 0) {
      return window.location.href.split('end_date=')[
        window.location.href.split('end_date=').length - 1
      ]
    } else {
      return getCurrentDate()
    }
  }

  const getSelectedTab = () => {
    const searchParams = new URLSearchParams(window.location.search)
    let selected_tab = searchParams.get('selected_tab')
    if (selected_tab) {
      return Number(selected_tab)
    }
    return ShowTabs.Resumen
  }

  const getCurrentDate = () => new Date().getFullYear() + '-' + getMonth() + '-' + getDay()

  const getMonth = () => {
    const m = new Date().getMonth() + 1
    if (m < 10) {
      return '0' + m
    } else {
      return m
    }
  }

  const getDay = () => {
    const d = new Date().getDate()
    if (d < 10) {
      return '0' + d
    } else {
      return d
    }
  }

  const searchInput = React.createRef()
  const [data, setData] = useState([])
  const [result2, setResult2] = useState([])
  const [result3, setResult3] = useState([])
  const [isFetching, setIsFetching] = useState(true)
  const [isActiveFilter, setFilterToggle] = useState(false)
  const [filterData, setFilterData] = useState([])
  const [typeFilter, setTypeFilter] = useState({})
  const [startDate, setStartDate] = useState(getStartDate())
  const [endDate, setEndDate] = useState(getEndDate())
  const [invalidRangeLimit, setInvalidRangeLimit] = useState(false)
  const [invalidRangeFormat, setInvalidRangeFormat] = useState(false)
  const [invalidDateFormat, setInvalidDateFormat] = useState(false)
  const [invalidRangeOffset, setInvalidRangeOffset] = useState(false)
  const [searchQuery, setSearchQuery] = useState(null)
  const [query, setQuery] = useState('')
  const [selectedTab, setSelectedTab] = useState(getSelectedTab())
  const [collapsed, setCollapsed] = useState(props.collapsed)
  const [showDropdown, setShowDropdown] = useState(false)

  ////// Stops fetchs when page is changed
  const abortController = new AbortController()
  const abortCurrentFetchs = () => {
    abortController.abort();
  };
  window.addEventListener("beforeunload", abortCurrentFetchs);
  //////

  useEffect(() => {
    async function fetchData() {
      await fetchDataFromServer()
    }

    fetchData()
  }, [])

  useEffect(() => {
    setCollapsed(props.collapsed)
  }, [props.collapsed])

  useEffect(() => {
    async function fetchData() {
      await fetchFilterDataFromServer()
    }

    fetchData()
  }, [])

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('selected_tab', selectedTab)
    let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString()
    window.history.pushState(null, '', newRelativePathQuery)
  }, [selectedTab])

  useEffect(() => {
    const closeDropdown = (e) => {
      if (!e.target.closest('.download-report-container')) {
        setShowDropdown(false)
      }
    }

    window.addEventListener('click', closeDropdown)
    return () => window.removeEventListener('click', closeDropdown)
  }, [])

  const fetchDataFromServer = () => {
    fetch(
      BACKEND_URL +
        '/businesses/1/reports/ubiqua_app?start_date=' +
        startDate +
        '&end_date=' +
        endDate +
        '&q=' +
        query +
        '&business_tenant_uid=' +
        cookie.load('business_tenant_uid'),
      {
        method: 'GET',
        signal: abortController.signal,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
      .then((response) => {
        if (response.status === 401) {
          cookie.remove('token', { path: '/' })
          window.location = '/'
        }
        return response.json()
      })
      .then((data) => {
        setData(data['result'])
        setResult2(data['result2'])
        setResult3(data['result3'])
        setIsFetching(false)
      })
      .catch((e) => console.log(e))
  }

  const fetchFilterDataFromServer = () => {
    fetch(
      BACKEND_URL +
        '/businesses/1/reports/filters_by_branches?business_tenant_uid=' +
        cookie.load('business_tenant_uid'),
      {
        method: 'GET',
        signal: abortController.signal,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
      .then((response) => {
        if (response.status === 401) {
          cookie.remove('token', { path: '/' })
          window.location = '/'
        }
        return response.json()
      })
      .then((data) => {
        setFilterData(data['result'])
      })
      .catch((e) => console.log(e))
  }

  const showModel = (event, user_db_ref) => {
    event.preventDefault()
    window.location =
      '/usuario_reportes?user_db_ref=' +
      user_db_ref +
      '&start_date=' +
      startDate +
      '&end_date=' +
      endDate
  }

  const searchFromDates = (event) => {
    const today = new Date()
    const start = document.getElementsByName('start_date')[0].value
    const end = document.getElementsByName('end_date')[0].value
    var start_date = new Date(start)
    var end_date = new Date(end)
    var diff_in_time = end_date.getTime() - start_date.getTime()
    const diff_in_time_from_today = today.getTime() - start_date.getTime()
    // To calculate the no. of days between two dates
    var diff_in_days = diff_in_time / (1000 * 3600 * 24)
    const diff_in_days_from_today = diff_in_time_from_today / (1000 * 3600 * 24)
    // Validations
    if (!start || !end) {
      setInvalidRangeFormat(false)
      setInvalidRangeLimit(false)
      setInvalidDateFormat(true)
      setInvalidRangeOffset(false)
    } else if (start_date > end_date) {
      setInvalidRangeFormat(true)
      setInvalidRangeLimit(false)
      setInvalidDateFormat(false)
      setInvalidRangeOffset(false)
    } else if (start_date > today || end_date > today) {
      setInvalidRangeFormat(false)
      setInvalidRangeLimit(false)
      setInvalidDateFormat(false)
      setInvalidRangeOffset(true)
    } else if (diff_in_days > 366 || diff_in_days_from_today > 366) {
      setInvalidRangeFormat(false)
      setInvalidRangeLimit(true)
      setInvalidDateFormat(false)
      setInvalidRangeOffset(false)
    } else {
      setInvalidRangeFormat(false)
      setInvalidRangeLimit(false)
      setInvalidDateFormat(false)
      setInvalidRangeOffset(false)

      const searchParams = new URLSearchParams(window.location.search)
      searchParams.set('start_date', start)
      searchParams.set('end_date', end)
      window.location.search = searchParams.toString()
    }
  }

  const handleSearch = (event) => {
    // search action occurs after ENTER is pressed
    if (event.keyCode === 13) {
      setSearchQuery(event.target.value)
    }
  }

  const getUiHeaderById = (id) => {
    return uiHeaders.find((header) => header.id === id)
  }

  const getRowPropertyValue = (row, propertyId, position = 0) => {
    let byPassSelectedTab = getUiHeaderById(propertyId)?.fieldNames[position]?.hasOwnProperty(
      `${ShowTabs.All}`
    )
    return row[
      getUiHeaderById(propertyId).fieldNames[position][
        byPassSelectedTab ? ShowTabs.All : selectedTab
      ]
    ]
  }

  function processPayments(result2) {
    let paymentTotals = {
      'Transferencia Bancaria': 0,
      'Depósito Bancario': 0,
      'Tarjeta de Crédito': 0,
      Efectivo: 0,
      'Pago Digital': 0,
      Cheque: 0,
      'Vale Digital': 0
    }
    result2.forEach((item) => {
      if (item.used_payment_methods_json) {
        const paymentMethods = JSON.parse(item.used_payment_methods_json)
        if (paymentMethods && Array.isArray(paymentMethods)) {
          paymentMethods.forEach((method) => {
            if (paymentTotals.hasOwnProperty(method.type)) {
              paymentTotals[method.type] += parseFloat(method.amount)
            }
          })
        }
      }
    })

    const reportPaymentData = Object.keys(paymentTotals).map((paymentType) => ({
      text: paymentType,
      value: paymentTotals[paymentType],
      businessTotal: paymentTotals[paymentType]
    }))

    return reportPaymentData
  }

  const buildReportMotives = () => {
    // "{"Cliente con mercancía","Cliente sin plata"}~{"Cliente sin plata"}"
    let reportMotives = []
    let allReportMotives = []
    let reportMotivesMap = new Map()

    for (const row of data) {
      let reportMotiveRaw = row[`no_visit_report_motives_${DicRolesTab[selectedTab]}`]
      if (!reportMotiveRaw) continue
      let reportMotivesPerRow = reportMotiveRaw.split('~')
      for (const reportMotivePerRow of reportMotivesPerRow) {
        let cleanReportMotive = reportMotivePerRow.replace(/{|}|"/g, '').split(',')
        for (const reportMotive of cleanReportMotive) {
          if (!reportMotive) continue
          allReportMotives.push(reportMotive)
          let reportMotiveCount = allReportMotives.filter((rm) => rm === reportMotive).length
          reportMotivesMap.set(reportMotive, reportMotiveCount)
        }
      }
    }

    for (const [key, value] of reportMotivesMap) {
      reportMotives.push({ text: key, value: value })
    }
    return reportMotives
  }

  const buildKpiData = () => {
    let overview = []
    let geolocation,
      totalOrder,
      totalRefund,
      totalDelivery,
      totalCharge,
      reportMotives,
      posTask,
      settlements
    switch (selectedTab) {
      case ShowTabs.Resumen:
      case ShowTabs.Pedidos:
      case ShowTabs.Pos:
      case ShowTabs.Devoluciones:
        {
          let totalClientsToVisit = 0,
            totalVisitedClients = 0,
            totalEffectiveVisitClient = 0,
            posEffectiveVisitClient = 0,
            posVisitClients = 0,
            totalGeocerca = 0,
            totalEffectiveGeocerca = 0,
            settlementActiveUser = 0,
            settlementTotalDone = 0,
            activeUser = 0,
            _totalOrder = 0,
            _totalRefund = 0,
            _totalDelivery = 0,
            _totalCharge = 0
          for (const row of data) {
            if (filterRow(row)) {
              totalVisitedClients += Number(getRowPropertyValue(row, 'visited_clients', 0))
              totalClientsToVisit += Number(getRowPropertyValue(row, 'visited_clients', 1))
              totalEffectiveVisitClient += Number(
                getRowPropertyValue(row, 'effective_visited_clients', 0)
              )
              settlementTotalDone += Number(getRowPropertyValue(row, 'done_settlement', 0))
              totalEffectiveGeocerca += Number(getRowPropertyValue(row, 'geo', 0))
              totalGeocerca += Number(getRowPropertyValue(row, 'geo', 1))
              posEffectiveVisitClient += Number(getRowPropertyValue(row, 'in_site_task', 0))
              posVisitClients += Number(getRowPropertyValue(row, 'in_site_task', 1))
              _totalOrder += Number(getRowPropertyValue(row, 'total_order', 0))
              _totalRefund += Number(getRowPropertyValue(row, 'total_refund', 0))
              _totalDelivery += Number(getRowPropertyValue(row, 'total_delivery', 0))
              _totalCharge += Number(getRowPropertyValue(row, 'total_charge', 0))
              activeUser++
              if (
                row?.user_active_roles?.includes('delivery') ||
                row?.user_active_roles?.includes('charge')
              ) {
                settlementActiveUser++
              }
            }
          }
          let totalVisitedClientsPercent = safePercent(totalVisitedClients, totalClientsToVisit)
          let totalEffectiveVisitClientPercent = safePercent(
            totalEffectiveVisitClient,
            totalVisitedClients
          )
          overview = [
            { text: 'Clientes visitados', value: `${totalVisitedClientsPercent}%` },
            { text: 'Visitas efectivas', value: `${totalEffectiveVisitClientPercent}%` },
            { text: 'Usuarios activos', value: activeUser }
          ]
          geolocation = `${totalEffectiveGeocerca}/${totalGeocerca}`
          posTask = `${posEffectiveVisitClient}/${posVisitClients}`
          settlements = `${settlementTotalDone}/${settlementActiveUser}`
          totalOrder = formatMoney(_totalOrder)
          totalRefund = formatMoney(_totalRefund)
          totalCharge = formatMoney(_totalCharge)
          totalDelivery = formatMoney(_totalDelivery)
        }
        break
      case ShowTabs.Cobros:
      case ShowTabs.Entregas: {
        let totalClientsToVisit = 0,
          totalVisitedClients = 0,
          totalEffectiveVisitClient = 0,
          totalGeocerca = 0,
          totalEffectiveGeocerca = 0,
          settlementActiveUser = 0,
          settlementTotalDone = 0,
          deliveredDocs = 0,
          docsToDeliver = 0,
          _totalOrder = 0,
          _totalRefund = 0,
          _totalDelivery = 0,
          _totalCharge = 0
        for (const row of data) {
          if (filterRow(row)) {
            totalVisitedClients += Number(getRowPropertyValue(row, 'visited_clients', 0))
            totalClientsToVisit += Number(getRowPropertyValue(row, 'visited_clients', 1))
            totalEffectiveVisitClient += Number(
              getRowPropertyValue(row, 'effective_visited_clients', 0)
            )
            totalEffectiveGeocerca += Number(getRowPropertyValue(row, 'geo', 0))
            totalGeocerca += Number(getRowPropertyValue(row, 'geo', 1))
            _totalDelivery += Number(getRowPropertyValue(row, 'total_delivery', 0))
            _totalCharge += Number(getRowPropertyValue(row, 'total_charge', 0))
            deliveredDocs += Number(getRowPropertyValue(row, 'deliveried_docs', 0))
            docsToDeliver += Number(getRowPropertyValue(row, 'deliveried_docs', 1))
            if (row?.user_active_roles?.includes('delivery') && selectedTab === ShowTabs.Entregas) {
              settlementActiveUser++
            }
            if (row?.user_active_roles?.includes('charge') && selectedTab === ShowTabs.Cobros) {
              settlementActiveUser++
            }
          }
        }
        let totalVisitedClientsPercent = safePercent(totalVisitedClients, totalClientsToVisit)
        let totalEffectiveVisitClientPercent = safePercent(
          totalEffectiveVisitClient,
          totalVisitedClients
        )
        let totalDeliveredDocs = safePercent(deliveredDocs, docsToDeliver)
        overview = [
          { text: 'Clientes visitados', value: `${totalVisitedClientsPercent}%` },
          { text: 'Visitas efectivas', value: `${totalEffectiveVisitClientPercent}%` },
          { text: 'Documentos entregados', value: `${totalDeliveredDocs}%` }
        ]
        geolocation = `${totalEffectiveGeocerca}/${totalGeocerca}`
        totalOrder = formatMoney(_totalOrder)
        totalRefund = formatMoney(_totalRefund)
        totalCharge = formatMoney(_totalCharge)
        totalDelivery = formatMoney(_totalDelivery)
        settlements = `${settlementTotalDone}/${settlementActiveUser}`
      }
    }
    return {
      overview,
      geolocation,
      posTask,
      settlements,
      totalOrder,
      totalRefund,
      totalCharge,
      totalDelivery,
      reportMotives: buildReportMotives()
    }
  }

  const filterResults = (q) => {
    const results = data.filter((visit) => {
      const poetName = visit.salesman_db_ref.toLowerCase() + visit.salesman_name.toLowerCase()
      return poetName.indexOf(q.toLowerCase()) !== -1
    })
    setQuery(q)
    // setSearchResults(results)
  }

  const deleteSearch = () => {
    filterResults('')
    searchInput.current.value = ''
  }

  const shouldDisplayField = (showArray) => {
    return showArray.includes(ShowTabs.All) || showArray.includes(selectedTab)
  }

  const displayValue = (data, header, forCsv = false) => {
    let fieldNames = header?.fieldNames
    let format = header?.format
    let formatCsv = header?.formatCsv

    if (!fieldNames) {
      return ''
    }

    let value = ''
    let byPassSelectedTab = fieldNames[0]?.hasOwnProperty(`${ShowTabs.All}`)

    if (fieldNames.length === 1) {
      if (byPassSelectedTab) {
        value = data[fieldNames[0][ShowTabs.All]]?.replace(/{|}/g, '')
      } else {
        value = data[fieldNames[0][selectedTab]]
      }
    }

    if (fieldNames.length === 2) {
      value = `${data[fieldNames[0][selectedTab]]}/${data[fieldNames[1][selectedTab]]}`
    }

    let applyForRolesOnly = header?.applyForRolesOnly
    if (applyForRolesOnly) {
      let userRolesStr = parseUserRoles(data.user_active_roles)
      let userRoles = userRolesStr.split(', ')
      for (const role of userRoles) {
        if (applyForRolesOnly.includes(ShowTabs[role])) {
          return format ? format(value) : value
        }
      }
      return 'N/A'
    }

    if (header.id === 'effective_visited_clients') {
      const effectiveVisits = getRowPropertyValue(data, 'effective_visited_clients', 0)
      const totalVisited = getRowPropertyValue(data, 'effective_visited_clients', 1)
      return header.format(effectiveVisits, totalVisited)
    }

    if (forCsv && formatCsv) {
      return formatCsv(value)
    }
    return format ? format(value) : value
  }

  const parseUserRoles = (userActiveRoles) => {
    let rolesSet = new Set()
    let rolesRaw = userActiveRoles.split('~')
    for (const roleRaw of rolesRaw) {
      let roles = roleRaw?.replace(/{|}/g, '')?.split(',')
      for (const role of roles) {
        if (role) rolesSet.add(DicRoles[role])
      }
    }
    return Array.from(rolesSet).join(', ')
  }

  const isSearchEnabled = () => {
    if (searchQuery) return true
    return false
  }

  const isUserInSearch = (row) => {
    return (
      row?.user_db_ref?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      row?.user_name?.toLowerCase().includes(searchQuery?.toLowerCase())
    )
  }

  const filterRow = (row) => {
    if (selectedTab == 1) {
      if (isSearchEnabled()) {
        return isUserInSearch(row)
      }
      return true
    }
    let userRolesStr = parseUserRoles(row.user_active_roles)
    let userRoles = userRolesStr.split(', ')
    for (const role of userRoles) {
      if (ShowTabs[role] && ShowTabs[role] == selectedTab) {
        if (isSearchEnabled()) {
          return isUserInSearch(row)
        }
        return true
      }
    }
    return false
  }

  function formatDate(dateyears) {
    const date = new Date(dateyears)
    return date.toLocaleDateString('es', { day: '2-digit', month: '2-digit', year: 'numeric' })
  }

  function formatTime(time) {
    const date = new Date(time)
    return date.toLocaleTimeString('es', { hour: '2-digit', minute: '2-digit', hour12: true })
  }

  const buildCsvFile = () => {
    let headers = new Set()
    let rows = []

    for (let i = 0; i < data.length; i++) {
      let d = data[i]
      let row = []
      for (let j = 0; j < uiHeaders.length; j++) {
        let header = uiHeaders[j]
        if (shouldDisplayField(header.show)) {
          headers.add(header.label.toUpperCase())
          if (filterRow(d)) row.push(displayValue(d, header, true))
        }
      }
      rows.push(row)
    }

    rows.splice(0, 0, Array.from(headers))
    rows.splice(0, 0, [])
    rows.splice(0, 0, ['Fecha', startDate + ' - ' + endDate])
    rows.splice(0, 0, ['Supervisor', cookie.load('user_name')])
    rows.splice(0, 0, ['Reporte:', `Reporte de usuarios ${RoleLabels[selectedTab].toLowerCase()}`])
    return rows
  }

  const buildCsvFileGeneral = () => {
    let rows = []
    rows.push(['Reporte:', `Reporte de usuarios cobros detallado`])
    rows.push(['Supervisor', cookie.load('user_name')])
    rows.push(['Fecha', startDate + ' - ' + endDate])
    rows.push([''])
    rows.push([''])
    const headersGeneral = [
      'CÓDIGO',
      'NOMBRE',
      'FECHA',
      'HORA',
      'CÓDIGO CLIENTE',
      'CLIENTE',
      'DENTRO DE GEOCERCA',
      'ACTIVIDAD',
      'MOTIVO/DESCRIPCIÓN',
      'MONTO',
      'UBICACIÓN LAT.',
      'UBICACIÓN LONG.'
    ]

    const mappingGeneral = {
      CÓDIGO: 'user_db_ref',
      NOMBRE: 'user_name',
      FECHA: 'created_date',
      HORA: 'created_date',
      'CÓDIGO CLIENTE': 'client_db_ref',
      CLIENTE: 'client_name',
      'DENTRO DE GEOCERCA': 'effective_location',
      ACTIVIDAD: 'activity_name',
      'MOTIVO/DESCRIPCIÓN': 'motive_text',
      MONTO: 'total',
      'UBICACIÓN LAT.': 'latitude',
      'UBICACIÓN LONG.': 'longitude'
    }
    let generalDetails = [[''], [''], headersGeneral]

    result3.forEach((detail) => {
      let detailArrayGeneral = headersGeneral.map((header) => {
        switch (header) {
          case 'CÓDIGO':
            return "'" + detail[mappingGeneral[header]]
          case 'FECHA':
            return formatDate(detail[mappingGeneral[header]])
          case 'HORA':
            return formatTime(detail[mappingGeneral[header]])
          case 'DENTRO DE GEOCERCA':
            return detail[mappingGeneral[header]] ? 'SI' : 'NO'
          case 'MOTIVO/DESCRIPCIÓN':
            return detail[mappingGeneral[header]] || 'empty'
          case 'MONTO':
            return detail[mappingGeneral[header]] ? formatMoney(detail[mappingGeneral[header]]) : 'empty';
            case 'UBICACIÓN LAT':
              return detail[mappingGeneral[header]] || 'empty';
              case 'UBICACIÓN LONG':
                return detail[mappingGeneral[header]] || 'empty';
          default:
            return detail[mappingGeneral[header]]
        }
      })
      generalDetails.push(detailArrayGeneral)
    })

    rows = rows.concat(generalDetails)
    rows.push([''])

    return rows
  }

  const getListTabStyles = (tab) => {
    return selectedTab == tab ? 'active' : ''
  }

  const getAnchorTabStyles = (tab) => {
    return selectedTab == tab ? 'cursor-pointer' : 'inactive-tab cursor-pointer'
  }

  const cardsSection = (kpiData) => {
    return (
      <>
        <div className="cards-container">
          <OverviewCard data={kpiData.overview} />
          <SimpleCard
            title={'Liquidaciones \n\n'}
            subtitle={kpiData?.settlements}
            selectedTab={selectedTab}
            displayTab={[ShowTabs.Resumen, ShowTabs.Entregas, ShowTabs.Cobros]}
          />
          <SimpleCard
            title={'Tareas en punto \n de venta'}
            subtitle={kpiData?.posTask}
            selectedTab={selectedTab}
            displayTab={[ShowTabs.Resumen, ShowTabs.Pos]}
          />
          <GeoCard geoData={kpiData.geolocation} />

          {kpiData.reportMotives && kpiData.reportMotives.length > 0 ? (
            <ReportMotiveCard reportMotives={kpiData.reportMotives} selectedTab={selectedTab} />
          ) : null}

           {result2 && result2.length > 0 && (
            <ReportPayment
              reportPayment={processPayments(result2)}
              selectedTab={selectedTab}
              displayTab={[ShowTabs.Resumen, ShowTabs.Cobros]}
              subtitle={kpiData.totalCharge}
            />
          )} 

        </div>
        <div className="cards-container">
          <AmountCard
            title={'Total Pedidos'}
            subtitle={kpiData.totalOrder}
            icon={'icon_pedidos'}
            selectedTab={selectedTab}
            displayTab={[ShowTabs.Resumen, ShowTabs.Pedidos]}
          />
          <AmountCard
            title={'Total Devoluciones'}
            subtitle={kpiData.totalRefund}
            icon={'icon_devoluciones'}
            selectedTab={selectedTab}
            displayTab={[ShowTabs.Resumen, ShowTabs.Devoluciones]}
          />
          <AmountCard
            title={'Total Cobros'}
            subtitle={kpiData.totalCharge}
            icon={'icon_cobros'}
            selectedTab={selectedTab}
            displayTab={[ShowTabs.Resumen, ShowTabs.Cobros]}
          />
          <AmountCard
            title={'Total Entregas'}
            subtitle={kpiData.totalDelivery}
            icon={'icon_entregas'}
            selectedTab={selectedTab}
            displayTab={[ShowTabs.Resumen, ShowTabs.Entregas]}
          />
        </div>
      </>
    )
  }

  if (isFetching) {
    return (
      <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
        <div className="index-header">
          <br />
          <p>Obteniendo datos ....</p>
        </div>
      </div>
    )
  } else {
    return (
      <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
        {invalidRangeOffset && (
          <div className="alert alert-danger" role="alert">
            <strong>
              ERROR: No se pueden seleccionar fechas posteriores a hoy. Por favor escoger otra
              fecha.
            </strong>
          </div>
        )}
        {invalidRangeLimit && (
          <div className="alert alert-danger" role="alert">
            <strong>
              ERROR: Rango de fechas escogidas pasan de los 12 meses. Por favor escoger otra fecha.
            </strong>
          </div>
        )}
        {invalidRangeFormat && (
          <div className="alert alert-danger" role="alert">
            <strong>ERROR: Fecha de inicio debe ser antes que la fecha de fin.</strong>
          </div>
        )}
        {invalidDateFormat && (
          <div className="alert alert-danger" role="alert">
            <strong>
              ERROR: Formato de fecha inválido. Por favor verifique que las fechas están escritas
              correctamente.
            </strong>
          </div>
        )}
        <div className="index-header">
          <h2 className="mb-20 float-unset">{props.title}</h2>
        </div>
        <div className="index-table">
          <div className="show-area-tab">
            <div>
              <div className="mb-1">
                <ul className="nav nav-tabs">
                  {Object.entries(ShowTabs).map(([key, value]) => {
                    return value ? (
                      <li
                        key={value}
                        onClick={() => setSelectedTab(value)}
                        id="details_tab"
                        className={getListTabStyles(value)}
                      >
                        <a className={getAnchorTabStyles(value)}>{RoleLabels[ShowTabs[key]]}</a>
                      </li>
                    ) : null
                  })}
                </ul>
              </div>
            </div>
            <div className="range-dates mt-25">
              <span style={{ color: '#4D4845', fontSize: '18px', marginRight: '15px' }}>Desde</span>
              <input
                max={getCurrentDate()}
                defaultValue={startDate}
                className="select-date-report"
                type="date"
                name="start_date"
                required
              />
              <span style={{ color: '#4D4845', fontSize: '18px', marginRight: '15px' }}>
                {' '}
                - Hasta
              </span>
              <input
                max={getCurrentDate()}
                defaultValue={endDate}
                className="select-date-report"
                type="date"
                name="end_date"
                required
              />
              <button onClick={searchFromDates} className="search-range-dates-btn">
                Buscar
              </button>
              {selectedTab === ShowTabs.Cobros ? (
                <div className="download-report-container">
                  <button
                    onClick={() => setShowDropdown(!showDropdown)}
                    className="btn btn-default download-report-button-drop"
                  >
                    <FontAwesomeIcon icon={faArrowCircleDown} />
                    Descargar reporte
                    <span className="download-report-button-drop-divider"></span>
                    <FontAwesomeIcon icon={faSortDown} />
                  </button>
                  {showDropdown && (
                    <div className="dropdown-menu-drop">
                      <CSVLink
                        className="dropdown-item-drop"
                        data={buildCsvFile()}
                        filename={`ReporteUsuarios${RoleLabels[selectedTab]}_${cookie.load(
                          'business_name'
                        )}_${getCurrentDate()}.csv`}
                        onClick={() => setShowDropdown(false)}
                      >
                        Reporte de cobros
                      </CSVLink>
                      <CSVLink
                        className="dropdown-item-drop"
                        data={buildCsvFileGeneral()}
                        filename={`ReporteDeActividadesCobrosTodosLosUsuarios_${cookie.load(
                          'business_name'
                        )}_${getCurrentDate()}.csv`}
                        onClick={() => setShowDropdown(false)}
                      >
                        Reporte de actividades - Todos los usuarios
                      </CSVLink>
                    </div>
                  )}
                </div>
              ) : (
                <CSVLink
                  className="btn btn-default download-report-button-no-drop"
                  data={buildCsvFile()}
                  filename={`ReporteUsuarios${RoleLabels[selectedTab]}_${cookie.load(
                    'business_name'
                  )}_${getCurrentDate()}.csv`}
                >
                  <FontAwesomeIcon icon={faArrowCircleDown} />
                  Descargar reporte
                </CSVLink>
              )}
            </div>
            <h5>
              <FontAwesomeIcon icon={faExclamationCircle} />
              Nota: Solo puedes escoger un rango de fechas máximo de 12 meses.
            </h5>
            <hr />
            <div className="show-header">
              <div className="col-12">
                {cardsSection(buildKpiData())}
                <hr style={{ marginLeft: '1rem' }} />
              </div>
              <div className="col-xs-6 report-index-title">
                <h2>Detalles de reporte:</h2>
              </div>
              <div className="col-xs-6 report-index-search">
                <div className="input-group show-buttons">
                  <input
                    ref={searchInput}
                    autoComplete="off"
                    onKeyDown={handleSearch}
                    className="report-search"
                    placeholder="Buscar usuario por código o nombre"
                  />
                  {query === '' && (
                    <span className="glyphicon glyphicon-search form-control-feedback" />
                  )}
                  {query !== '' && (
                    <span className="delete-search-icon" onClick={deleteSearch.bind(this)}>
                      &times;
                    </span>
                  )}
                </div>
              </div>
            </div>
            <hr />
            <div className="report-index-table">
              <Table>
                <thead>
                  <tr>
                    {uiHeaders.map((header, index) => {
                      return shouldDisplayField(header.show) ? (
                        <th key={index}>
                          <div className="table-header-container" title={header.description}>
                            <span className="th-header-text">{header.label.toUpperCase()}</span>
                            <FontAwesomeIcon icon={faQuestionCircle} />
                          </div>
                        </th>
                      ) : null
                    })}
                  </tr>
                </thead>
                <tbody>
                  {data.map((d, k) => {
                    return filterRow(d) ? (
                      <tr key={k}>
                        {uiHeaders.map((header, j) => {
                          const textAlignStyle = ['code', 'name'].includes(header.id)
                            ? {}
                            : { textAlign: 'center' }

                          return shouldDisplayField(header.show) ? (
                            <td
                              className="cursor-pointer td-text-limited"
                              onClick={(e) => {
                                showModel(e, d.user_db_ref)
                              }}
                              style={textAlignStyle}
                              key={j}
                            >
                              {displayValue(d, header)}
                            </td>
                          ) : null
                        })}
                      </tr>
                    ) : null
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default EffectivenessIndexView
