import React, { useEffect, useState } from 'react'
import cookie from 'react-cookies'
import Select from 'react-select'
import { DISCARD_CHANGES_CONFIRMATION } from '../constants/appMessages'
import { validateAccessRole } from '../helpers/userRole'
import { LocalPagination } from '../layout/LocalPagination'
// import AttributeView from './attributes/AttributeView'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export default function EssentialIndexView(props) {
  const [qualifiersData, setQualifiersData] = useState(null)
  const [searchInput, setSearchInput] = useState('')
  const [segmentsData, setSegmentsData] = useState(null)
  const [isFetching, setFetching] = useState(false)
  const [isSaving, setSaving] = useState(false)
  const [isUpdatingQualifier, setUpdatingQualifier] = useState(false)
  const [lastSelectedQualifier, setLastSelectedQualifier] = useState('')
  const [savedQualifier, setSavedQualifier] = useState('')
  const [attributes, setAttributes] = useState([])
  const [segmentList, setSegmentList] = useState({})
  const [currentPageData, setCurrentPageData] = useState([])
  const [collapsed, setCollapsed] = useState(props.collapsed)

  const [showAlert, setShowAlert] = useState(false)

  ////// Stops fetchs when page is changed
  const abortController = new AbortController()
  const abortCurrentFetchs = () => {
    abortController.abort();
  };
  window.addEventListener("beforeunload", abortCurrentFetchs);
  //////

  const handleChange = (event) => {
    event.preventDefault()
    if (event.target.name === 'qualifierAssociated') {
      setLastSelectedQualifier(event.target.value)
    }
  }

  const handleSearch = (e) => {
    setSearchInput(e.target.value)
  }

  const handleEnterSearch = (e) => {
    if (e.key === 'Enter') {
      // buildSearchQuery()
    }
  }

  const handlePageChange = (newData) => {
    setCurrentPageData(newData)
  }

  const handleSubmitUpdateQualifier = (event) => {
    event.preventDefault()
    setSavedQualifier(lastSelectedQualifier)
    saveQualifiers()
    setUpdatingQualifier(false)
  }

  const handleCancelUpdateQualifier = (event) => {
    event.preventDefault()
    if (window.confirm(DISCARD_CHANGES_CONFIRMATION)) {
      setLastSelectedQualifier(savedQualifier)
      setUpdatingQualifier(false)
    }
  }

  const navigateToAssociateEssential = (e, segment) => {
    window.location =
      '/asociar_infaltable?attribute=' + lastSelectedQualifier + '&segment=' + segment
  }

  useEffect(() => {
    setCollapsed(props.collapsed)
  }, [props.collapsed])
  
  useEffect(() => {
    handleSegmentsData()
  }, [segmentsData])

  const buildSegmentArray = (segments, attributeValue) => {
    let arr = []
    let label = attributes.find((e) => e.value === attributeValue)?.label
    if (segments && segments?.length > 0) {
      for (let i = 0; i < segments.length; i++) {
        const segment = segments[i]
        arr.push({ label, segment })
      }
    }
    return arr
  }
  const hasQualifiers = () => {
    const { saved_qualifiers } = qualifiersData?.result || {}
    return (
      saved_qualifiers &&
      (saved_qualifiers.qualifier_2 ||
        saved_qualifiers.qualifier_3 ||
        saved_qualifiers.qualifier_4 ||
        saved_qualifiers.qualifier_5)
    )
  }

  const handleSegmentsData = () => {
    let toSegmentList = {
      qualifier_2: buildSegmentArray(segmentsData?.qualifier_2, 'qualifier_2'),
      qualifier_3: buildSegmentArray(segmentsData?.qualifier_3, 'qualifier_3'),
      qualifier_4: buildSegmentArray(segmentsData?.qualifier_4, 'qualifier_4'),
      qualifier_5: buildSegmentArray(segmentsData?.qualifier_5, 'qualifier_5')
    }
    setSegmentList(toSegmentList)
  }

  useEffect(() => {
    handleQualifierData()
  }, [qualifiersData])

  const handleCloseAlert = () => {
    setShowAlert(false)
  }

  const handleQualifierData = async () => {
    let availableQualifiers = qualifiersData?.result?.available_qualifiers
    let savedQualifiers = qualifiersData?.result?.saved_qualifiers
    let toAttributes = []

    if (savedQualifiers && savedQualifiers?.essential_qualifier_value) {
      setLastSelectedQualifier(savedQualifiers.essential_qualifier_value)
      setSavedQualifier(savedQualifiers.essential_qualifier_value)
    }
    if (savedQualifiers && savedQualifiers?.qualifier_2) {
      // qualifier 2 - Atributo A
      let qualifier2 = availableQualifiers.find(
        (item) => item.value === savedQualifiers?.qualifier_2
      )
      toAttributes.push({
        value: 'qualifier_2',
        name: `Atributo A - ${qualifier2?.label}`,
        label: qualifier2?.label,
        key: qualifier2?.value
      })

      // qualifier 3 - Atributo B
      if (savedQualifiers?.qualifier_3) {
        let qualifier3 = availableQualifiers.find(
          (item) => item.value === savedQualifiers?.qualifier_3
        )
        toAttributes.push({
          value: 'qualifier_3',
          name: `Atributo B - ${qualifier3?.label}`,
          label: qualifier3?.label,
          key: qualifier3?.value
        })
      }

      // qualifier 4 - Atributo C
      if (savedQualifiers?.qualifier_4) {
        let qualifier4 = availableQualifiers.find(
          (item) => item.value === savedQualifiers?.qualifier_4
        )
        toAttributes.push({
          value: 'qualifier_4',
          name: `Atributo C - ${qualifier4?.label}`,
          label: qualifier4?.label,
          key: qualifier4?.value
        })
      }

      // qualifier 5 - Atributo D
      if (savedQualifiers?.qualifier_5) {
        let qualifier5 = availableQualifiers.find(
          (item) => item.value === savedQualifiers?.qualifier_5
        )
        toAttributes.push({
          value: 'qualifier_5',
          name: `Atributo D - ${qualifier5?.label}`,
          label: qualifier5?.label,
          key: qualifier5?.value
        })
      }
      setAttributes(toAttributes)
    }
  }

  const fetchQualifiersFromServer = async () => {
    setFetching(true)
    const response = await fetch(
      BACKEND_URL + '/qualifiers' + '?business_tenant_uid=' + cookie.load('business_tenant_uid'),
      {
        method: 'GET',
        signal: abortController.signal,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )
    if (response.status === 401) {
      return console.error(response)
    }
    const data = await response.json()
    setQualifiersData(data)
    setFetching(false)
  }

  const fetchPromoQualifiers = async () => {
    setFetching(true)
    const response = await fetch(BACKEND_URL + '/promo_qualifiers', {
      method: 'GET',
      signal: abortController.signal,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
    if (response.status === 401) {
      return console.error(response)
    }

    const data = await response.json()
    setSegmentsData(data.result)
    setFetching(false)
  }

  const saveQualifiers = async () => {
    setSaving(true)
    const response = await fetch(
      BACKEND_URL + '/qualifiers' + '?business_tenant_uid=' + cookie.load('business_tenant_uid'),
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        },
        body: JSON.stringify({
          business_tenant_uid: cookie.load('business_tenant_uid'),
          essential_qualifier_value: lastSelectedQualifier
        })
      }
    )
    if (response.status === 401) {
      return console.error(response)
    }
    const data = await response.json()
    // await fetchQualifiersFromServer(false)
    setSaving(false)
    setSavedQualifier(lastSelectedQualifier)

    fetchQualifiersFromServer()
  }

  const getDisplayableData = () => {
    let toDisplay = []
    if (segmentList && segmentList[savedQualifier]) {
      let allData = segmentList[savedQualifier]

      if (searchInput != '') {
        toDisplay = allData.filter((item) => {
          return item.segment?.toLowerCase().includes(searchInput.toLowerCase())
        })
      } else {
        toDisplay = allData
      }
    }
    return toDisplay
  }

  useEffect(() => {
    async function fetcher() {
      await fetchQualifiersFromServer()
      await fetchPromoQualifiers()
    }
    fetcher()
  }, [])

  useEffect(() => {
    if (savedQualifier) {
      fetchPromoQualifiers()
    }
  }, [savedQualifier])

  if (isFetching === false) {
    return (
      <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
        {showAlert && (
          <div className="alert alert-info" role="alert">
            <strong>
              Debe establecer al menos un segmento para poder configurar los infaltables.{' '}
              <a href="/segmentos">Ir a Segmentos</a>
            </strong>
            <button
              type="button"
              className="close"
              onClick={() => setShowAlert(false)}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        )}
        <div>
          <div className="index-header detailed-container">
            <h2>Productos Infaltables</h2>

            {isUpdatingQualifier === false &&
              validateAccessRole(cookie.load('user_role')?.essentials_module)?.can_write && (
                <div className="index-buttons">
                  <button
                    onClick={() => {
                      if (!hasQualifiers()) {
                        setShowAlert(true)
                      } else {
                        setUpdatingQualifier(true)
                        setShowAlert(false)
                      }
                    }}
                  >
                    Actualizar Atributo
                  </button>
                </div>
              )}
          </div>
        </div>
        <div className="index-table">
          <div className="show-area">
            <div className="header-infaltables-container">
              <div className="full-width">
                <p>
                  <b>Nota: </b>
                  Los productos infaltables son productos que deben tener mayor importancia o
                  prioridad en la gestión de ventas. Estos productos se pueden configurar siguiendo
                  su segmentación de clientes. Debe seleccionar un solo atributo y configurar los
                  productos infaltables para cada segmento de ese atributo.
                </p>
              </div>

              <div className="full-width header-infaltables-button-group-left">
                {isUpdatingQualifier === true && (
                  <div>
                    <button className="cancel-button" onClick={handleCancelUpdateQualifier}>
                      Cancelar
                    </button>
                    <input
                      className="save-button"
                      value="Guardar"
                      type="button"
                      onClick={handleSubmitUpdateQualifier}
                    />
                  </div>
                )}
              </div>
            </div>
            <hr className="mb-10" />
            <div className="mb-30 mt-30 infaltables-attribute-selector-container">
              <h4 className="mr-10">Atributo asociado a infaltables:</h4>
              {isFetching && <h4> Cargando...</h4>}
              {!isUpdatingQualifier && savedQualifier == '' && !isFetching && <h4> SIN ASIGNAR</h4>}
              {!isUpdatingQualifier && savedQualifier !== '' && !isFetching && (
                <h4>{attributes.find((e) => e.value == lastSelectedQualifier)?.name}</h4>
              )}
              {isUpdatingQualifier === true && (
                <div className="">
                  <select
                    className="essential-qualifier-select"
                    onChange={handleChange}
                    name="qualifierAssociated"
                  >
                    <option value="" hidden>
                      Seleccione un atributo
                    </option>
                    {attributes.map((segment, i) => {
                      return (
                        <option key={i} value={segment.value}>
                          {segment.name}
                        </option>
                      )
                    })}
                  </select>
                </div>
              )}
            </div>
            <div className="mt-20">
              <h4>Asocie el listado de productos:</h4>
              <p className="mb-20">
                Usted puede asociar una lista de productos individual a cada segmento.
              </p>

              <div className="title-and-search-containter">
                <h4 className=""></h4>
                <div className="search-input-containter search-input-margin">
                  <input
                    value={searchInput}
                    onChange={handleSearch}
                    onKeyDown={handleEnterSearch}
                    className="round-search-input"
                    placeholder="Buscar segmento"
                  />
                  {searchInput === '' && (
                    <span className="glyphicon glyphicon-search form-control-feedback" />
                  )}
                  {searchInput && (
                    <div
                      title="Limpiar texto"
                      onClick={() => {
                        setSearchInput('')
                      }}
                      className="round-search-input-x"
                    >
                      X
                    </div>
                  )}
                </div>
              </div>

              <table className="table table-hover" style={{ width: '100%', tableLayout: 'fixed' }}>
                <thead>
                  <tr>
                  <th style={{ verticalAlign: 'middle', width: '25%' }}>
                      <span className="th-header-text">#</span>
                    </th>
                    <th style={{ verticalAlign: 'middle' }}>
                      <span className="th-header-text">ATRIBUTO</span>
                    </th>
                    <th style={{ verticalAlign: 'middle', width: '10%' }}>
                      <span className="th-header-text">SEGMENTO</span>
                    </th>
                    <th
                      style={{ textAlign: 'right', paddingRight: '75px', verticalAlign: 'middle' }}
                    >
                      ACCIÓN
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {savedQualifier == '' && (
                    <tr>
                      <td>No tiene configuraciones activas en estos momentos</td>
                    </tr>
                  )}
                  {savedQualifier !== '' && (!currentPageData || currentPageData?.length == 0) && (
                    <tr>
                      <td>No hay resultados</td>
                    </tr>
                  )}
                  {savedQualifier !== '' &&
                    currentPageData?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td className="td-text-limited">{i + 1}</td>
                          <td className="td-text-limited">{item.label}</td>
                          <td className="td-text-limited">{item.segment}</td>
                          <td className="td-text-limited" style={{ textAlign: 'right' }}>
                            {!isUpdatingQualifier && !isSaving && (
                              <button
                                onClick={(e) => navigateToAssociateEssential(e, item.segment)}
                                className="ubiqua-secondary-btn"
                                type="button"
                              >
                                Ver{' '}
                                {validateAccessRole(cookie.load('user_role')?.essentials_module)
                                  ?.can_write
                                  ? 'o asociar'
                                  : ''}{' '}
                                productos
                              </button>
                            )}
                            {(isUpdatingQualifier || isSaving) && (
                              <button
                                title="Debe guardar los cambios para poder asociar productos"
                                disabled
                                className="ubiqua-secondary-btn disabled"
                                type="button"
                              >
                                Ver{' '}
                                {validateAccessRole(cookie.load('user_role')?.essentials_module)
                                  ?.can_write
                                  ? 'o asociar'
                                  : ''}{' '}
                                productos
                              </button>
                            )}
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
            <LocalPagination
              data={getDisplayableData()}
              onPageChange={handlePageChange}
              searchInput={searchInput}
              display={getDisplayableData()?.length > 50}
            />
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
        <div className="show-title">
          <p>Obteniendo datos ....</p>
        </div>
      </div>
    )
  }
}
