import React from 'react'
import ReactDOM from 'react-dom'
import Pagination from '../layout/Pagination'
import CreateOrderInput from './component/protected_component/CreateOrderInput'
import SearchInput from '../layout/SearchInput'
import { formatMoney } from '../helpers/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faDownload, faCalendar } from '@fortawesome/free-solid-svg-icons'
import ReactFilter from '../components/inputs/ReactFilterList'
import Toolbar from 'react-multi-date-picker/plugins/toolbar'
import DatePicker from 'react-multi-date-picker'

export default class OrderIndexView extends React.Component {
  showModel = (event) => {
    event.preventDefault()
    const id = event.target.id
    window.open('/pedidos/' + id, '_blank')
  }

  getFormattedDate = (createdDate) => {
    const d = new Date(createdDate)
    return d.toLocaleString()
  }

  redirectToCreateOrderPage = () => {
    window.location = '/nuevo_pedido'
  }

  handleChange = (event) => {
    const { value } = event.target
    this.props.onSearch(value)
  }

  handleInputChange = (event) => {
    const newSearchInput = event.target.value
    this.props.onSearchInput(newSearchInput)
  }

  handleFilterChange = (identifier, selectedOptions) => {
    const selectedLabels = Array.from(selectedOptions)
    this.props.updateAllSelectedOptions(identifier, selectedLabels)
  }

  render() {
    const data = this.props.data
    const { filters } = this.props
    const filtersArray = Array.isArray(filters) ? filters : Array.from(filters || []);
    const anyFilterEmpty = filtersArray.some(filter => filter.value.length === 0);    
    const { fetchDataStatus } = this.props
    if (this.props.isFetching) {
      return (
        <div className={`main-view ${this.props.collapsed ? 'collapsed' : ''}`}>
          <div className="index-header">
            <br />
            <p>Obteniendo datos ....</p>
          </div>
        </div>
      )
    } else {
      return (
        <div className={`main-view ${this.props.collapsed ? 'collapsed' : ''}`}>
          <div className="index-header detailed-container">
            <h2 className="mb-20">{this.props.title}</h2>
            <CreateOrderInput redirectToCreateOrderPage={this.redirectToCreateOrderPage} />
          </div>
          <div className="index-table">
            <div className="show-area">
              <h4 className="">Filtrar pedidos por:</h4>
              <div className="filters-search-container mb-10">
                <FontAwesomeIcon className="filter-icon mr-10" icon={faFilter} />
                {!fetchDataStatus?.filters ? (
                  <span>Obteniendo filtros...</span>
                ) : (
                  <div className="filters-container">
                    {Array.from(filters).map((filter, indx) => (
                      <div key={`flr-${indx}`} className="mr-10">
                        <ReactFilter
                          className="mr-10"
                          title={filter.key}
                          identifier={filter.key}
                          options={filter.value}
                          resetWatcher={this.props.filterVersion}
                          disabled={anyFilterEmpty}

                          onSelectionChange={this.handleFilterChange}
                        />
                      </div>
                    ))}
                    <DatePicker
                                              disabled={anyFilterEmpty}

                      range
                      rangeHover
                      dateSeparator=" - "
                      months={[
                        'Enero',
                        'Febrero',
                        'Marzo',
                        'Abril',
                        'Mayo',
                        'Junio',
                        'Julio',
                        'Agosto',
                        'Septiembre',
                        'Octubre',
                        'Noviembre',
                        'Diciembre'
                      ]}
                      weekDays={['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab']}
                      format="DD MMM"
                      weekStartDayIndex={1}
                      highlightToday={false}
                      numberOfMonths={2}
                      maxDate={new Date()}
                      onChange={(dateObjects) => this.props.handleDateFilter(dateObjects)}
                      value={this.props.selectedDates} 
                      plugins={[
                        <Toolbar
                          position="bottom"
                          names={{
                            deselect: 'Deseleccionar'
                          }}
                          sort={['deselect']}
                        />
                      ]}
                      render={(value, openCalendar) => {
                        return (
                          <div key={`flr-date`} 
                          className={`mr-10 ${anyFilterEmpty ? 'react-filter-disabled' : ''}`}
                          >
                            <div className="react-filter-box filter-input-width-datepicker">
                              <div
                                title="Rango de fechas"
                                onClick={!anyFilterEmpty ? openCalendar : undefined}
                                className="react-filter-box-clickable-zone"
                              >
                                <div className="react-filter-titles">
                                  {value?.length > 0 && (
                                    <>
                                      {Array.isArray(value) ? (
                                        <div className="filter-title-overflow">
                                          {value.join(' - ')}
                                        </div>
                                      ) : (
                                        <div className="filter-title-overflow">{value}</div>
                                      )}
                                      <FontAwesomeIcon
                                        style={{ color: 'ef823a' }}
                                        icon={faCalendar}
                                      />
                                    </>
                                  )}
                                  {value?.length == 0 && (
                                    <>
                                      <div className="filter-title-overflow">RANGO DE FECHAS</div>
                                      <FontAwesomeIcon
                                  style={{ color: anyFilterEmpty ? '#D5D5D5' : '#597fa9' }}
                                  icon={faCalendar}
                                />                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }}
                    />
                  </div>
                )}
                <div className="search-input-containter">
                  <input
                    value={this.props.searchInput}
                    onChange={this.props.handleSearch}
                    onKeyDown={this.props.handleEnterSearch}
                    className="round-search-input"
                    style={{ fontSize: 12 }}
                    placeholder="Buscar pedido por codigo o nombre"
                  />
                  {this.props.searchInput === '' && (
                    <span className="glyphicon glyphicon-search form-control-feedback" />
                  )}
                  {this.props.searchInput && (
                    <div
                      title="Limpiar texto"
                      onClick={() => {
                        this.props.resetReactFilters()
                      }}
                      className="round-search-input-x"
                    >
                      X
                    </div>
                  )}
                </div>
              </div>
              <div className="mb-20">
                {this.props.isAnyFilterSelected && (
                  <a
                    onClick={this.props.resetReactFilters}
                    className="reset-filter cursor-pointer mb-20"
                  >
                    Restablecer filtros aplicados
                  </a>
                )}
              </div>
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th style={{ verticalAlign: 'middle', width: '10%' }}>FECHA Y HORA</th>
                    <th style={{ verticalAlign: 'middle', width: '10%' }}># DE PEDIDO</th>
                    <th style={{ verticalAlign: 'middle', width: '15%' }}>CÓDIGO DE CLIENTE</th>
                    <th style={{ verticalAlign: 'middle', width: '20%' }}>NOMBRE DE CLIENTE</th>
                    <th style={{ verticalAlign: 'middle', width: '15%' }}>CREADO POR</th>
                    <th style={{ verticalAlign: 'middle', width: '10%' }}>TOTAL PEDIDO</th>
                    <th style={{ verticalAlign: 'middle', width: '10%' }}>TOTAL FACTURA</th>
                    <th style={{ verticalAlign: 'middle', width: '15%', textAlign: 'left' }}>
                      ESTADO DEL PEDIDO
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.length > 0 ? (
                    data.map((model, index) => (
                      <tr className="cursor-pointer" key={index}>
                        <td className="td-text-limited" id={model['uuid']} onClick={this.showModel}>
                          {this.getFormattedDate(model['created_date'])}
                        </td>
                        <td className="td-text-limited" id={model['uuid']} onClick={this.showModel}>
                          {model['order_db_ref']}
                        </td>
                        <td className="td-text-limited" id={model['uuid']} onClick={this.showModel}>
                          {model['client_db_ref']}
                        </td>
                        <td className="td-text-limited" id={model['uuid']} onClick={this.showModel}>
                          {model['client_name']}
                        </td>
                        <td className="td-text-limited" id={model['uuid']} onClick={this.showModel}>
                          {model['salesman_db_ref']} - {model['salesman_name']}
                        </td>
                        <td className="td-text-limited" id={model['uuid']} onClick={this.showModel}>
                          {formatMoney(Number(model['order_total']))}
                        </td>
                        <td className="td-text-limited" id={model['uuid']} onClick={this.showModel}>
                          {model['invoice_total'] > 0
                            ? formatMoney(Number(model['invoice_total']))
                            : '-'}
                        </td>

                        {model['order_status'] == 'sent' && (
                          <td
                            style={{ display: 'flex', justifyContent: 'center' }}
                            id={model['uuid']}
                            onClick={this.showModel}
                          >
                            <div className="td-order-status">
                              <div className="order-status sent-style">ENVIADO</div>
                            </div>
                          </td>
                        )}
                        {model['order_status'] == 'received' && (
                          <td
                            style={{ display: 'flex', justifyContent: 'center' }}
                            id={model['uuid']}
                            onClick={this.showModel}
                          >
                            <div className="td-order-status">
                              <div className="order-status received-style">RECIBIDO</div>
                            </div>
                          </td>
                        )}
                        {model['order_status'] == 'dispatched' && (
                          <td
                            style={{ display: 'flex', justifyContent: 'center' }}
                            id={model['uuid']}
                            onClick={this.showModel}
                          >
                            <div className="td-order-status">
                              <div className="order-status dispatched-style">DESPACHADO</div>
                            </div>
                          </td>
                        )}
                        {model['order_status'] == 'billed' && (
                          <td
                            style={{ display: 'flex', justifyContent: 'center' }}
                            id={model['uuid']}
                            onClick={this.showModel}
                          >
                            <div className="td-order-status">
                              <div className="order-status billed-style">FACTURADO</div>
                            </div>
                          </td>
                        )}
                        {model['order_status'] == 'cancelled' && (
                          <td
                            style={{ display: 'flex', justifyContent: 'center' }}
                            id={model['uuid']}
                            onClick={this.showModel}
                          >
                            <div className="td-order-status">
                              <div className="order-status cancelled-style">CANCELADO</div>
                            </div>
                          </td>
                        )}
                        {model['order_status'] == 'tobebilled' && (
                          <td
                            style={{ display: 'flex', justifyContent: 'center' }}
                            id={model['uuid']}
                            onClick={this.showModel}
                          >
                            <div className="td-order-status">
                              <div className="order-status pending-bill-style">POR FACTURAR</div>
                            </div>
                          </td>
                        )}
                        {model['order_status'] == 'ontheway' && (
                          <td
                            style={{ display: 'flex', justifyContent: 'center' }}
                            id={model['uuid']}
                            onClick={this.showModel}
                          >
                            <div className="td-order-status">
                              <div className="order-status dispatched-style">EN CAMINO</div>
                            </div>
                          </td>
                        )}
                        {model['order_status'] == 'partial' && (
                          <td
                            style={{ display: 'flex', justifyContent: 'center' }}
                            id={model['uuid']}
                            onClick={this.showModel}
                          >
                            <div className="td-order-status">
                              <div className="order-status partial-style">PARCIAL</div>
                            </div>
                          </td>
                        )}
                        {model['order_status'] == 'annulled' && (
                          <td
                            style={{ display: 'flex', justifyContent: 'center' }}
                            id={model['uuid']}
                            onClick={this.showModel}
                          >
                            <div className="td-order-status">
                              <div className="order-status annulled-style">ANULADO</div>
                            </div>
                          </td>
                        )}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="td-text-limited" colSpan="4">
                        No hay resultados
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <nav className="index-table">
            {this.props.pagination.total_pages > 1 && (
              <div onClick={this.props.handlePaginationClick}>
                <Pagination
                  current_page={this.props.pagination.current_page}
                  total_pages={this.props.pagination.total_pages}
                />
              </div>
            )}
          </nav>
        </div>
      )
    }
  }
}
