import React, { useEffect, useState } from 'react'
import { GlobalContext } from './GlobalContext'
import variant from '../flags_variants/variant'
import _ from 'lodash'
import cookie from 'react-cookies'
import { validateAccessRole } from '../helpers/userRole'

const APP_NAME = process.env.REACT_APP_BUSINESS_NAME.toLowerCase()
const FlagProvider = ({ children }) => {
  const [data, setData] = useState({})
  /* The core information will be initialized with null,
   to give the view a chance to reload the values expected by the context
   and not a default value that can affect the logic in the navbar */
  const [shouldViewCoreInfo, setShouldViewCoreInfo] = useState(null)
  const [shouldViewTransactionInfo, setShouldViewTransactionInfo] = useState(null)
  const [shouldCreateOrder, setShouldCreateOrder] = useState(false)
  const [hasTenantReport, setTenantReport] = useState(false)
  const [redirectPath, setRedirectPath] = useState(false)
  const [hasAppEntrega, setHasAppEntrega] = useState(false)
  const [useAve, setUseAve] = useState(false)
  const [userRole, setUserRole] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      const variants = await JSON.parse(JSON.stringify(variant))
      const lookup = _.keyBy(variants, (pr) => pr.clientName)
      setData(lookup[APP_NAME])
      setShouldCreateOrder(lookup[APP_NAME].shouldCreateOrder)
      setShouldViewCoreInfo(lookup[APP_NAME].shouldViewCoreInfo)
      setShouldViewTransactionInfo(lookup[APP_NAME].shouldViewTransactionInfo)
      setRedirectPath(lookup[APP_NAME].redirectPath)
      setTenantReport(lookup[APP_NAME].hasTenantReport)
      setHasAppEntrega(lookup[APP_NAME].hasAppEntrega)
      setUseAve(lookup[APP_NAME].useAve)
      setUserRole(cookie.load('user_role') || {})
    }
    fetchData()
  }, [])

  const configRoleUser = userRole ? validateAccessRole(userRole.can_config_user) : false
  const canViewReport = userRole ? validateAccessRole(userRole.can_view_report) : false
  const canViewCore = userRole ? validateAccessRole(userRole.can_view_core) : false
  const canMakePromo = userRole ? validateAccessRole(userRole.can_make_promo) : false

  const getRedirectPath = () => {
    if (validateAccessRole(userRole?.product_module)?.can_read) return 'productos'
    if (validateAccessRole(userRole?.clients_module)?.can_read) return 'clientes'
    if (validateAccessRole(userRole?.segments_module)?.can_read) return 'segmentos'
    if (validateAccessRole(userRole?.essentials_module)?.can_read) return 'infaltables'
    if (validateAccessRole(userRole?.price_list_module)?.can_read) return 'listas_de_precios'
    if (validateAccessRole(userRole?.can_make_promo)?.can_read) return 'promociones'
    if (validateAccessRole(userRole?.orders_modules)?.can_read) return 'pedidos'
    if (validateAccessRole(userRole?.can_config_user)?.can_read) return 'usuarios'
    if (validateAccessRole(userRole?.collections_module)?.can_read) return 'liquidaciones'
    if (validateAccessRole(userRole?.can_view_report)?.can_read) return 'reportes'
    if (validateAccessRole(userRole?.tableau_dashboard_module)?.can_read) return 'dashboard'
    return 'inicio'
  }

  // Users with user configuration role, cannot access report and core info
  const supervisorRole = true //!configRoleUser.can_read
  const shouldViewCoreInfoByRole = shouldViewCoreInfo && supervisorRole && canViewCore.can_read
  const canMakePromoRole = canMakePromo.can_write
  const redirectPathByRole = getRedirectPath()

  return (
    <GlobalContext.Provider
      value={{
        variants: data,
        userRole: userRole,
        supervisorRole,
        shouldCreateOrder: shouldCreateOrder,
        shouldViewCoreInfo: shouldViewCoreInfoByRole,
        shouldViewTransactionInfo: shouldViewTransactionInfo,
        redirectPath: redirectPathByRole,
        canMakePromoRole: canMakePromoRole,
        hasAppEntrega: hasAppEntrega,
        hasTenantReport: hasTenantReport,
        useAve: useAve,
        configPermission: configRoleUser.can_read,
        canViewReport: canViewReport.can_read
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}

export default FlagProvider
