import React from 'react'
import Pagination from '../layout/Pagination'
import { withRouter } from 'react-router-dom'
import { validateAccessRole } from '../helpers/userRole'
import cookie from 'react-cookies'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import ReactFilter from '../components/inputs/ReactFilterList'

class ClientIndexView extends React.Component {
  redirectToCreateClient() {
    window.location = '/crear_cliente'
  }

  showModel = (event) => {
    event.preventDefault()
    const id = event.target.id
    window.open('/clientes/' + id + '/detalles')
  }

  handleChange = (event) => {
    const { value } = event.target
    this.props.onSearch(value)
  }

  handleInputChange = (event) => {
    const newSearchInput = event.target.value
    this.props.onSearchInput(newSearchInput)
  }

  render() {
    const data = this.props.data
    const { filters } = this.props;
    const filtersArray = Array.isArray(filters) ? filters : Array.from(filters || []);
    const anyFilterEmpty = filtersArray.some(filter => filter.value.length === 0);
    
    if (this.props.isFetching) {
      return (
        <div className={`main-view ${this.props.collapsed ? 'collapsed' : ''}`}>
          <div className="index-header">
            <br />
            <p>Obteniendo datos ...</p>
          </div>
        </div>
      )
    } else {
      return (
        <div className={`main-view ${this.props.collapsed ? 'collapsed' : ''}`}>
          <div className="index-header detailed-container">
            <h2>{this.props.title}</h2>
            <div className="index-buttons">
              {validateAccessRole(cookie.load('user_role')?.clients_module)?.can_write && (
                <button onClick={() => this.redirectToCreateClient()}>Crear cliente nuevo</button>
              )}
            </div>
          </div>
          <div className="index-table">
            <div className="show-area">
              <h4 className="">Filtrar clientes por:</h4>
              <div className="filters-search-container mb-10">
                <FontAwesomeIcon className="filter-icon mr-10" icon={faFilter} />
                  <div className="filters-container">
                    {filters &&
                      Array.from(filters).map((filter, indx) => (
                        <div key={`flr-${indx}`} className="mr-10">
                          <ReactFilter
                            className="mr-10"
                            title={filter.key}
                            identifier={filter.key}
                            options={filter.value}
                            resetWatcher={this.props.resetReactFilters}
                            onSelectionChange={this.props.updateAllSelectedOptions}
                            disabled={anyFilterEmpty}
                          />
                        </div>
                      ))}
                  </div>
                <div className="search-input-containter">
                  <input
                    value={this.props.searchInput}
                    onChange={this.props.handleSearch}
                    onKeyDown={this.props.handleEnterSearch}
                    className="round-search-input"
                    style={{ fontSize: 12 }}
                    placeholder="Buscar clientes por código o nombre de cliente"
                  />
                  {this.props.searchInput === '' && (
                    <span className="glyphicon glyphicon-search form-control-feedback" />
                  )}
                  {this.props.searchInput && (
                    <div
                      title="Limpiar texto"
                      onClick={() => {
                        this.props.resetFilters()
                      }}
                      className="round-search-input-x"
                    >
                      X
                    </div>
                  )}
                </div>
              </div>
              <div className="mb-20">
                {this.props.isAnyFilterSelected && (
                  <a
                    onClick={this.props.resetFilters}
                    className="reset-filter cursor-pointer mb-20"
                  >
                    Restablecer filtros aplicados
                  </a>
                )}
              </div>
              <div></div>
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th style={{ verticalAlign: 'middle', width: '15%' }}>
                      <span className="th-header-text">CÓDIGO</span>
                    </th>
                    <th style={{ verticalAlign: 'middle', width: '38%' }}>
                      <span className="th-header-text">NOMBRE DEL CLIENTE</span>
                    </th>
                    <th
                      className="th-end-clients"
                      style={{ verticalAlign: 'middle', width: '20%' }}
                    >
                      <span className="th-header-text">LISTA DE PRECIO ASIGNADA</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.length > 0 ? (
                    data.map((model, index) => (
                      <tr className="cursor-pointer" key={index}>
                        <td
                          className="td-text-limited"
                          style={{
                            verticalAlign: 'middle',
                            textAlign: 'left',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                          }}
                          id={model['id']}
                          onClick={(e) => this.showModel(e)}
                        >
                          {model['db_ref']}
                        </td>
                        <td
                          className="td-text-limited"
                          style={{
                            verticalAlign: 'middle',
                            textAlign: 'left',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                          }}
                          id={model['id']}
                          onClick={(e) => this.showModel(e)}
                        >
                          {model['name']}
                        </td>
                        {model['price_list'] && (
                          <td
                            style={{
                              verticalAlign: 'middle',
                              textAlign: 'left',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap'
                            }}
                            id={model['id']}
                            onClick={(e) => this.showModel(e)}
                          >
                            {model['price_list']['description']}
                          </td>
                        )}
                        {!model['price_list'] && (
                          <td
                            className="td-text-limited"
                            style={{
                              verticalAlign: 'middle',
                              textAlign: 'left',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap'
                            }}
                            id={model['id']}
                            onClick={(e) => this.showModel(e)}
                          >
                            {model['price_list']
                              ? model['price_list']['description']
                              : 'SIN ASIGNAR'}
                          </td>
                        )}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="1" >
                        No hay resultados
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <nav className="index-table">
            {this.props.pagination.total_pages > 1 && (
              <div onClick={this.props.handlePaginationClick}>
                <Pagination
                  current_page={this.props.pagination.current_page}
                  total_pages={this.props.pagination.total_pages}
                />
              </div>
            )}
          </nav>
        </div>
      )
    }
  }
}

export default withRouter(ClientIndexView)