import cookie from 'react-cookies'
import taxSettings from '../flags_variants/tax_settings.json'
import documentTypes from '../flags_variants/document_types.json'
export const getDiscountPrice = (price, discount) =>
  discount && discount > 0 ? price - price * (discount / 100) : price

export const capitalizeFirstLetter = (word) => word.charAt(0).toUpperCase()

export const capitalizeFirstLetterFrom = (sentence) => {
  let letter = capitalizeFirstLetter(sentence)
  let sliced = sentence.slice(1)
  return letter + sliced
}

export const formatPrice = (price) => `P${price}`

export const formatDate = (date) => new Date(date).toLocaleDateString('en-US')

export const formatDiscount = (data, decimals = 5) => {
  if (parseFloat(data) > 100 || parseFloat(data) < 0) {
    return 0
  }
  if (data) {
    const regex = new RegExp('(\\.\\d{' + decimals + '})\\d+', 'g')
    const validDiscountNumber = data.replace(regex, '$1')
    return parseFloat(validDiscountNumber)
  } else {
    return 0
  }
}

export const formatMoney = (amount) => {
  let locale = cookie.load('intl_locale');
  let currency = cookie.load('intl_currency');

  let totalAmount = Number(amount).toFixed(2)
  if (locale && currency && locale !== 'null' && currency !== 'null') {
    return new Intl.NumberFormat(locale, { style: 'currency', currency: currency }).format(totalAmount);
  } else {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totalAmount);
  }
}

export const effectiveLocationStr = (effectiveLocation, clientLocation) => {
  if (!clientLocation || Number(clientLocation) == 0) return 'N/A'
  if (effectiveLocation) return 'Sí'
  return 'No'
}

export const effectiveLocationWithAllowedLocation = (effectiveLocation, hasAllowedLocation) => {
  if (!hasAllowedLocation) return 'N/A'
  if (effectiveLocation) return 'Sí'
  return 'No'
}

export const safePercent = (value, of) => {
  let validValue = Number(value)
  let validOf = Number(of)
  let result = validOf === 0 ? 0 : Math.round((validValue / validOf) * 100)
  if (result > 100) {
    result = 100
  }
  return result
}

export const endsVowel = (str) => {
  for (const i of 'aeiou') {
    if (str.endsWith(i)) {
      return true
    }
  }
  return false
}

export const setUpTaxSettings = (intlLocale) => {
  if (intlLocale && (intlLocale != null || intlLocale != '')) {
    let ts = taxSettings[intlLocale]
    if (ts) {
      cookie.save('tax_settings', ts, { path: '/' })
      return
    }
  }
  cookie.save('tax_settings', taxSettings['default'], { path: '/' })
}

export const parseDocumentType = (documentType) => {
  let dt = documentTypes[documentType]
  if (dt) {
    return dt
  }
  return documentType
}

export const replaceWithBr = (str) => {
  return str.replace(/\n/g, '<br />')
}

export const clearCoordinates = (coord) => {
  if (!coord) return null
  let newCoord = parseFloat(coord)
  if (isNaN(newCoord)) return null
  if (newCoord === 0) return null
  return coord
}
