const STATUS_DIC = {
  active: 'Activa',
  unstarted: 'No iniciada',
  expired: 'Caducada',
  suspended: 'Suspendida'
}

const TASK_TYPE_DIC = {
  images: 'Imágenes',
  inventory: 'Captura de inventario',
  survey: 'Encuesta',
  data_update: 'Actualización de datos',
  planimetry: 'Verificación de planimetría',
  price_capture: 'Captura de precios propios y/o competencia'
}

const PROD_TYPE_DIC = {
  brand: 'Marca',
  individual_products: 'Producto individual',
  category: 'Categoría'
}

export { STATUS_DIC, TASK_TYPE_DIC, PROD_TYPE_DIC }
