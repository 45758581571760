import React, { useRef, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FixedSizeList as List } from 'react-window'

export default function ReactMultipleFilter({
  title,
  optionsKeys,
  optionsDic,
  onChange,
  resetWatcher,
  allSelectedOptions,
  disabled
}) {
  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState(new Set())
  const [selectedFilterType, setSelectedFilterType] = useState(null)
  const [availableOptions, setAvailableOptions] = useState([])
  const [search, setSearch] = useState('')
  const [selectAll, setSelectAll] = useState(true)
  const filterContainer = useRef(null)

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (filterContainer.current && !filterContainer.current.contains(e.target)) {
        setDropdownOpen(false)
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)
    return () => document.removeEventListener('mousedown', handleOutsideClick)
  }, [])

  useEffect(() => {
    if (disabled) {
      setDropdownOpen(false)
    }
  }, [disabled])

  useEffect(() => {
    setSearch('')
    setSelectedFilterType(null)
    setAvailableOptions([])
    setSelectedOptions(new Set())
  }, [resetWatcher])

  useEffect(() => {
    if (selectedFilterType) {
      const selectedFilterOptions =
        optionsDic.find((o) => o.key === selectedFilterType)?.value || []
      setAvailableOptions(selectedFilterOptions)
      setSelectAll(false)
    }
  }, [selectedFilterType, optionsDic])

  useEffect(() => {
    const allSelected =
      availableOptions.length > 0 && availableOptions.every((option) => selectedOptions.has(option))
    setSelectAll(allSelected)
  }, [selectedOptions, availableOptions])

  const selectFilterType = (option) => {
    if (selectedFilterType !== option) {
      setSelectedFilterType(option)
      const selectedFilterOptions = optionsDic.find((o) => o.key === option)?.value || []
      setAvailableOptions(selectedFilterOptions)
    }
  }

  const selectOption = (option) => {
    if (disabled) return
    const newSelected = new Set(selectedOptions)
    if (newSelected.has(option)) {
      newSelected.delete(option)
    } else {
      newSelected.add(option)
    }
    setSelectedOptions(newSelected)

    // Actualiza el estado global después de modificar la selección de una opción
    updateAllSelectedOptions(newSelected)
  }

  const handleFilterSearch = (e) => {
    if (disabled) return
    setSearch(e.target.value)
  }

  const shouldDisplay = (option) =>
    search === '' || option.toLowerCase().includes(search.toLowerCase())

  const filterOptions = () => availableOptions.filter(shouldDisplay)

  const numOptionsToShow = filterOptions().length
  const OPTION_HEIGHT = 35
  const MAX_OPTIONS_VISIBLE = 8
  const containerMaxHeight = Math.min(numOptionsToShow, MAX_OPTIONS_VISIBLE) * OPTION_HEIGHT

  const shouldShowSelectAllButton = filterOptions().length <= 50

  const toggleSelectAll = () => {
    const newSelected = new Set()
    if (!selectAll) {
      filterOptions().forEach((option) => newSelected.add(option))
    }
    setSelectedOptions(newSelected)
    setSelectAll(!selectAll)
    updateAllSelectedOptions(newSelected)
  }

  const updateAllSelectedOptions = (newSelected) => {
    if (selectedFilterType) {
      const newAllSelectedOptions = new Map(allSelectedOptions)
      if (newSelected.size > 0) {
        newAllSelectedOptions.set(selectedFilterType, Array.from(newSelected))
      } else {
        newAllSelectedOptions.delete(selectedFilterType)
      }
      onChange(newAllSelectedOptions)
    }
  }
  const RenderOption = ({ index, style }) => {
    const option = availableOptions[index]
    const isOptionSelected = selectedOptions.has(option)
    return (
      <div style={style} className="react-filter-option" onClick={() => selectOption(option)}>
        <input type="checkbox" className="filter-checkbox" readOnly checked={isOptionSelected} />
        <div className="filter-checkbox-label">{option}</div>
      </div>
    )
  }

  return (
    <div className={`react-filter-box ${disabled ? 'react-filter-disabled' : ''}`}>
      <div
        title={title}
        onClick={() => !disabled && setDropdownOpen(!isDropdownOpen)}
        className="react-filter-box-clickable-zone"
      >
        <div className="react-filter-titles">
          <div className="filter-title-overflow">{title.toUpperCase()}</div>
          {selectedOptions.size > 0 && (
            <span className="text-orange">({selectedOptions.size})</span>
          )}
          <FontAwesomeIcon icon={isDropdownOpen ? faChevronUp : faChevronDown} />
        </div>
      </div>
      {isDropdownOpen && (
        <div
          className="react-multiple-filter-dropdown-box"
          style={{ transition: 'all 0.2s', opacity: 1, visibility: 'visible' }}
          ref={filterContainer}
        >
          <div className="react-filter-option-nohover">
            <div className="filter-subtitle">Filtrar por:</div>
          </div>
          {optionsKeys.map((option, idx) => (
            <div className="react-filter-option-nohover" key={idx}>
              <input
                type="radio"
                className="filter-radio"
                id={`filterType-${idx}`}
                name="filterType"
                value={option}
                checked={selectedFilterType === option}
                onChange={() => selectFilterType(option)}
                disabled={optionsDic.find((o) => o.key === option)?.value.length === 0}
              />
              {optionsDic.find((o) => o.key === option)?.value.length == 0 ? (
                <div className="filter-radio-label-disabled">{option}</div>
              ) : (
                <div className="filter-radio-label">{option}</div>
              )}
            </div>
          ))}
          {selectedFilterType != null && availableOptions.length > 0 && (
            <>
              <p style={{ color: '#A29E9D' }}>________________________________</p>
              <div className="filter-search-input-container">
                <input
                  type="search"
                  className="filter-search-input"
                  placeholder="Palabras claves..."
                  value={search}
                  onChange={handleFilterSearch}
                  disabled={disabled}
                />
                {search && (
                  <div
                    title="Limpiar texto"
                    onClick={() => setSearch('')}
                    className="filter-search-input-x"
                  >
                    X
                  </div>
                )}
              </div>
              {!search && shouldShowSelectAllButton && (
                <div className="react-filter-option" onClick={toggleSelectAll}>
                  <input type="checkbox" className="filter-checkbox" readOnly checked={selectAll} />
                  <div className="filter-checkbox-label">
                    {selectAll ? 'Deseleccionar todos' : 'Seleccionar todos'}
                  </div>
                </div>
              )}

              {filterOptions().length > 0 ? (
                <List
                  height={300}
                  itemCount={availableOptions.length}
                  itemSize={35}
                  width="100%"
                  itemData={availableOptions}
                >
                  {RenderOption}
                </List>
              ) : (
                search && (
                  <div className="react-filter-option">
                    <div className="filter-checkbox-label">No hubo resultados</div>
                  </div>
                )
              )}
            </>
          )}
        </div>
      )}
    </div>
  )
}
