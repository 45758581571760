import React, { useContext, useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import cookie from 'react-cookies'
import ReactFilter from '../components/inputs/ReactFilterList'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faDownload, faCalendar } from '@fortawesome/free-solid-svg-icons'
import Pagination from '../layout/Pagination'
import { effectiveLocationWithAllowedLocation } from '../helpers/utils'
import { TASK_TYPE_DIC } from './constants/constants'
import DatePicker from 'react-multi-date-picker'
import PosTaskReport from '../report/pos_tasks/PosTaskReport'
import PosTaskEffectivityContext from './context/PosTaskEffectivityContext'
import Toolbar from 'react-multi-date-picker/plugins/toolbar'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

export default function PosTaskEffectivityView(props) {
  const {
    fetchDataStatus,
    clientsData,
    tasksData,
    pagination,
    setFilterQuery,
    setDateQuery,
    setSearchQuery,
    filterQuery,
    dateQuery,
    searchQuery,
    fetchClientsFromServer,
    fetchTasksFromServer,
    handlePaginationClick,
    title,
    setPagination  
  } = useContext(PosTaskEffectivityContext)
  const [searchInput, setSearchInput] = useState('')
  const [filters, setFilters] = useState(new Map())
  const [filtersDic, setFiltersDic] = useState(
    {"Tipo de Tarea":"task_type", 
    "Cliente": "client_uid"})
  const [taskDataDic, setTaskDataDic] = useState(
    {"Captura de inventario":"inventory", 
    "Encuesta": "survey", 
    "Verificación de planimetría": "planimetry",
    "Captura de precios propios y/o competencia": "price_capture"
  })
  const [resetReactFilters, setResetReactFilters] = useState(false)
  const [allSelectedOptions, setAllSelectedOptions] = useState(null)
  const [collapsed, setCollapsed] = useState(props.collapsed)
  const [reportTask, setReportTask] = useState([])
  const [answersSurvey, setAnswersTask] = useState([])
  const [imagesSurvey, setImagesTask] = useState([])
  const [reportTaskDownload, setReportTaskDownload] = useState([])
  const [selectedDates, setSelectedDates] = useState(null)

  const filtersDisabled = !clientsData || !tasksData || clientsData.length === 0;

  const isObjectNotEmpty = (obj) => obj && Object.keys(obj).length > 0

  useEffect(() => {
    buildFiltersQuery()
  }, [allSelectedOptions])

  useEffect(() => {
    handleFilterData()
  }, [clientsData])

  useEffect(() => {
    setCollapsed(props.collapsed)
  }, [props.collapsed])

  const handleSearch = (e) => {
    setSearchInput(e.target.value)
  }

  const handleEnterSearch = (e) => {
    if (e.key === 'Enter') {
      buildSearchQuery()
    }
  }

  const getTimestampFromDateTime = (date) => {
    const d = new Date(date)
    return d.toLocaleString()
  }

  const handlePlanimetryFulfilment = (planimetryFulfilled, taskType) => {
    if (taskType != 'planimetry') return 'N/A'
    if (planimetryFulfilled) return 'Sí'
    return 'No'
  }

  const buildSearchQuery = () => {
    let queryParam = ''
    if (searchInput) {
      queryParam = `&q=${searchInput}`
    }
    setSearchQuery(queryParam)
    setPagination(prev => ({ ...prev, current_page: 1 }));  

  }

  const handleSelectionChange = (identifier, selectedOptions) => {
    const selectedOptionsArray = Array.from(selectedOptions)
    setAllSelectedOptions((prevOptions) => {
      const newOptions = new Map(prevOptions)
      newOptions.set(identifier, selectedOptionsArray)
      return newOptions
    })
  }

  const buildFiltersQuery = () => {
    let queryParam = ''
    let taskArray = []
    let clientArray = []
    if (allSelectedOptions) {
      for (const [key, value] of allSelectedOptions) {
        if (value.length != 0) {
          if (key == 'Tipo de Tarea') {
            value.map((task) => {
              taskArray.push(taskDataDic[task])
            })
            queryParam += `&${filtersDic[key]}=${taskArray.join(',')}`
          } else if (key == 'Cliente') {
            value.map((v) => {
              let clientInfo = clientsData.find((c) => c[0] == v)
              clientArray.push(clientInfo[1])
            })
            queryParam += `&${filtersDic[key]}=${clientArray.join(',')}`
          } else {
            queryParam += `&${filtersDic[key]}=${value.join(',')}`
          }
        }
      }
      setFilterQuery(queryParam)
      setPagination(prev => ({ ...prev, current_page: 1 }));
    }
  }

  const handleFilterData = async () => {
    let availableFilters = [
      {key:'Tipo de Tarea', value: ['Captura de inventario', 'Verificación de planimetría', 'Encuesta', 'Captura de precios propios y/o competencia']}, 
      {key: "Cliente", value: []}]

    availableFilters[1].value = clientsData.map((c) => c[0])
    setFilters(availableFilters)
  }

  const handleDateFilter = (datesObj) => {
    let formatedDates = []
    let queryParam = ''
    let startDate = new Date()
    let endDate = new Date()
    if (!datesObj || datesObj.length == 0) {
      setDateQuery(null)
    } else if (datesObj) {
      datesObj.map((date) => {
        formatedDates.push(new Date(date.unix * 1000))
      })
      //When there is a date range
      if (formatedDates.length > 1) {
        if (formatedDates[0] < formatedDates[1]) {
          startDate = new Date(formatedDates[0])
          endDate = new Date(formatedDates[1])
        } else if (formatedDates[0] > formatedDates[1]) {
          startDate = new Date(formatedDates[1])
          endDate = new Date(formatedDates[0])
        }
      }
      //When is only one date
      else if (formatedDates.length == 1) {
        startDate = new Date(formatedDates[0])
        endDate = new Date(formatedDates[0])
      }
      queryParam = `&start_date=${startDate.toLocaleDateString()}&end_date=${endDate.toLocaleDateString()}`
      setDateQuery(queryParam)
    }
  }

  const resetFilters = () => {
    setAllSelectedOptions(null)
    setSearchInput('')
    setFilterQuery(null)
    setDateQuery(null)
    setSelectedDates(null)
    setResetReactFilters(!resetReactFilters)
  }
  const fetchTaskData = async (taskId) => {
    fetch(BACKEND_URL + '/pos_task_activities/' + taskId, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cookie.load('token')
      }
    })
      .then((response) => {
        if (response.status === 401) {
          window.location = '/'
        }
        if (response.status === 404) {
          return {
            error: true,
            message: 'No se encontró la tarea'
          }
        }
        return response.json()
      })
      .then((data) => {
        if (data.error) {
        } else {
          setReportTask(data)
          setAnswersTask(data.details_survey)
          setImagesTask(data.user_image_url)
          setReportTaskDownload(true)
        }
      })
      .catch((e) => console.log(e))
  }

  const handleDownloadClick = async (taskId) => {
    try {
      await fetchTaskData(taskId)
    } catch (error) {
      console.log('Error al obtener datos de la tarea:', error)
      setReportTaskDownload(false)
    }
  }

  useEffect(() => {
    setReportTaskDownload(false)
  }, [reportTask, reportTaskDownload])

  return (
    <div className={`main-view ${collapsed ? 'collapsed' : ''}`}>
      <div className="index-header detailed-container">
        <h2 className="mb-20 float-unset">{title}</h2>
      </div>
      <div className="index-table">
        <div className="show-area">
          <h4 className="">Filtrar tareas por:</h4>
          <div className="filters-search-container mb-10">
            <FontAwesomeIcon className="filter-icon mr-10" icon={faFilter} />
              <div className="filters-container">
                {Array.from(filters).map((filter, indx) => (
                  <div key={`flr-${indx}`} className="mr-10">
                    <ReactFilter
                      className="mr-10"
                      title={filter.key}
                      identifier={filter.key}
                      options={filter.value}
                      resetWatcher={resetReactFilters}
                      onSelectionChange={handleSelectionChange}
                      disabled={filtersDisabled} 
                    />
                  </div>
                ))}
                <DatePicker
                  disabled={filtersDisabled}
                  range
                  rangeHover
                  dateSeparator=" - "
                  months={[
                    'Enero',
                    'Febrero',
                    'Marzo',
                    'Abril',
                    'Mayo',
                    'Junio',
                    'Julio',
                    'Agosto',
                    'Septiembre',
                    'Octubre',
                    'Noviembre',
                    'Diciembre'
                  ]}
                  weekDays={['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab']}
                  format="DD MMM"
                  weekStartDayIndex={1}
                  highlightToday={false}
                  numberOfMonths={2}
                  maxDate={new Date()}
                  value={selectedDates}
                  onChange={(dateObjects) => {
                    handleDateFilter(dateObjects)
                    setSelectedDates(dateObjects)
                  }}
                  plugins={[
                    <Toolbar
                      position="bottom"
                      names={{
                        deselect: 'Deseleccionar'
                      }}
                      sort={['deselect']}
                    />
                  ]}
                  render={(value, openCalendar) => {
                    return (
                      <div key={`flr-date`} 
                      className={`mr-10 ${filtersDisabled ? 'react-filter-disabled' : ''}`}
                      >
                        <div className="react-filter-box filter-input-width-datepicker">
                          <div
                            title="Rango de fechas"
                            onClick={!filtersDisabled ? openCalendar : undefined}
                            className="react-filter-box-clickable-zone"
                          >
                            <div className="react-filter-titles">
                              {value?.length > 0 && (
                                <>
                                  {Array.isArray(value) ? (
                                    <div className="filter-title-overflow">{value.join(' - ')}</div>
                                  ) : (
                                    <div className="filter-title-overflow">{value}</div>
                                  )}
                                  <FontAwesomeIcon style={{ color: 'ef823a' }} icon={faCalendar} />
                                </>
                              )}
                              {value?.length == 0 && (
                                <>
                                  <div className="filter-title-overflow">RANGO DE FECHAS</div>
                                  <FontAwesomeIcon
                                   style={{ color: filtersDisabled ? '#D5D5D5' : '#597fa9' }}
                                   icon={faCalendar} 
                                   />
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }}
                />
              </div>
            <div className="search-input-containter">
              <input
                value={searchInput}
                onChange={handleSearch}
                onKeyDown={handleEnterSearch}
                className="round-search-input"
                style={{ fontSize: 12 }}
                placeholder="Buscar tareas por actividad o descripción"
              />
              {searchInput === '' && (
                <span className="glyphicon glyphicon-search form-control-feedback" />
              )}
              {searchInput && (
                <div
                  title="Limpiar texto"
                  onClick={() => {
                    setSearchInput('')
                    setSearchQuery('')
                  }}
                  className="round-search-input-x"
                >
                  X
                </div>
              )}
            </div>
          </div>
          <div className="mb-20">
            {(filterQuery || dateQuery) && (
              <a onClick={resetFilters} className="reset-filter cursor-pointer mb-20">
                Restablecer filtros aplicados
              </a>
            )}
          </div>
          <div>
            <Table responsive>
              <thead>
                <tr>
                  <th style={{ verticalAlign: 'middle' }}>
                    <span className="th-header-text">FECHA Y HORA</span>
                  </th>
                  <th style={{ verticalAlign: 'middle' }}>
                    <span className="th-header-text"># ACTIVIDAD</span>
                  </th>
                  <th style={{ verticalAlign: 'middle' }}>
                    <span className="th-header-text">CLIENTE/ATRIBUTO(S)</span>
                  </th>
                  <th style={{ verticalAlign: 'middle' }}>
                    <span className="th-header-text">TIPO DE TAREA</span>
                  </th>
                  <th style={{ verticalAlign: 'middle' }}>
                    <span className="th-header-text">DESCRIPCIÓN</span>
                  </th>
                  <th style={{ verticalAlign: 'middle' }}>
                    <span className="th-header-text">CÓDIGO</span>
                  </th>
                  <th style={{ verticalAlign: 'middle' }}>
                    <span className="th-header-text">CUMPLE TAREA</span>
                  </th>
                  <th style={{ verticalAlign: 'middle' }}>
                    <span className="th-header-text">DENTRO DE GEOCERCA</span>
                  </th>
                  <th style={{ verticalAlign: 'middle' }}>
                    <span className="th-header-text">DESCARGAR</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {tasksData?.map((task, indx) => (
                  <tr key={indx}>
                    <td className="td-text-limited">
                      {getTimestampFromDateTime(task.created_date)}
                    </td>
                    <td className="td-text-limited">{task.uid}</td>
                    <td className="td-text-limited">
                      {task.client_uid} - {task.client_name}
                    </td>
                    <td className="td-text-limited">{TASK_TYPE_DIC[task.task_type]}</td>
                    <td className="td-text-limited">{task.task_description}</td>
                    <td className="td-text-limited">{task.task_uuid.slice(9, 18)}</td>
                    <td className="td-text-limited" style={{ textAlign: 'center' }}>
                      {handlePlanimetryFulfilment(task.planimetry_fulfilled, task.task_type)}
                    </td>
                    <td className="td-text-limited" style={{ textAlign: 'center' }}>
                      {effectiveLocationWithAllowedLocation(
                        task.effective_location,
                        task.has_allowed_location
                      )}
                    </td>
                    <td style={{ display: 'flex', justifyContent: 'center' }}>
                      <button
                        className="dropdown-btn"
                        type="button"
                        onClick={() => handleDownloadClick(task.uuid)}
                      >
                        <FontAwesomeIcon icon={faDownload} style={{ margin: 0 }} />
                      </button>
                    </td>
                  </tr>
                ))}
                {tasksData?.length === 0 && (
                  <tr>
                    <td>No hubo resultados.</td>
                  </tr>
                )}
              </tbody>
            </Table>
            {fetchDataStatus?.tasks && <span>Obteniendo datos...</span>}
            {pagination.total_pages > 1 && (
              <div onClick={handlePaginationClick}>
                <Pagination
                  current_page={pagination.current_page}
                  total_pages={pagination.total_pages}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {reportTaskDownload && isObjectNotEmpty(reportTask) && (
        <PosTaskReport
          data={reportTask}
          answer={answersSurvey}
          images={imagesSurvey}
          shouldDownload={reportTaskDownload}
          setShouldDownload={setReportTaskDownload}
        />
      )}
    </div>
  )
}
