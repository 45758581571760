import React, { useContext, useEffect, useState, useRef  } from 'react'
import { effectiveLocationWithAllowedLocation, replaceWithBr } from '../../helpers/utils'
import { TASK_TYPE_DIC } from '../../pos/constants/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faArrowCircleDown } from '@fortawesome/free-solid-svg-icons'
import ImageModal from '../../components/image_modal/ImageModal'
import PosTaskSurvey from './PosTaskSurvey'
import { CSVLink } from 'react-csv'
import { getTimestampFromDateTime,getCurrentDate } from '../../helpers/formatDate'


export default function PosTaskReport({ data, reportDownloads , shouldDownload, setShouldDownload, answer, images}) {


    const typetask = (() => {
        if (data.task_type === 'planimetry') {
                return 'Verificacion de Planimetria';
            
        } else if (data.task_type === 'inventory') {
            return 'Captura de Inventario';
        } else if (data.task_type === 'survey') {
            return 'Encuesta';
        }
        return [];
    })();

    const getCurrentDate = () => new Date().getFullYear() + '-' + getMonth() + '-' + getDay()

    const getMonth = () => {
      const m = new Date().getMonth() + 1
      if (m < 10) {
        return '0' + m
      } else {
        return m
      }
    }
  
    const getDay = () => {
      const d = new Date().getDate()
      if (d < 10) {
        return '0' + d
      } else {
        return d
      }
    }
  
    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); 
        const year = date.getFullYear();
        const hours = date.getHours() % 12 || 12; 
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
        return `${day}-${month}-${year} - ${hours}:${minutes}${ampm}`;
    };


    const buildCsvData = (data, answer , images) => {
        if (data.task_type === 'planimetry') {
            if (data.task_data && Array.isArray(data.task_data.product_names) && data.task_data.product_names.length > 0) {
                return buildCsvPlanimetryProduct(data); 
            } else {
                return buildCsvPlanimetryBrand(data); 
            }
        } else if (data.task_type === 'inventory') {
            return buildCsvInventory(data); 
        } else if (data.task_type === 'survey') {
            return buildCsvSurvey(data ,answer , images);
        } else {
            return []; 
        }
    };
    const buildCsvPlanimetryBrand = (data) => {
        const taskTypeLabel = data.task_type === 'planimetry' ? 'Verificacion de Planimetria' : data.task_type;
        const description = data.task_data && typeof data.task_data === 'object' ? data.task_data.description : '';
        const Brand = data.task_data && typeof data.task_data === 'object' ? data.task_data.brand_name : '';
        const imageUrl = data.task_data && typeof data.task_data === 'object' && data.task_data.image_url
        ? data.task_data.image_url
        : 'No se ha agregado ninguna imagen';
        let rows = [];
        rows.push(['Reporte:', `Reporte detalles de tarea`]);
        rows.push(['Tipo de tarea:', taskTypeLabel]);
        rows.push(['Código de tarea:', data.uid || '']); 
        rows.push(['Cliente:', `${data.client_name || ''} - ${data.client_db_ref || ''}`]);
        rows.push(['Descripción:', description]);
        rows.push(['Cantidad de subtareas:', data.task_quantity || '']); 
        rows.push(['Dentro de Geocerca:', effectiveLocationWithAllowedLocation(data.effective_location, data.has_allowed_location)]); 
        rows.push(['']);
        rows.push(['Codigo de usuario:', data.user_uid || '']); 
        rows.push(['Usuario:', data.user_full_name || '']); 
        rows.push(['Fecha:', formatDate(data.created_date)]);
        rows.push(['']);
        rows.push(['PRODUCTOS:', 'Marca:', Brand|| '']); 
        if (data.planimetry_fulfilled) {
            rows.push(['CUMPLE PLANIMETRIA:', 'Sí']);
        } else {
            rows.push(['CUMPLE PLANIMETRIA:', 'NO', 'MOTIVO:' + ' ' + (data.comments || '')]);
        }
        rows.push(['IMAGEN DE REFERENCIA: ', imageUrl]); 
        rows.push(['FOTO DE EVIDENCIA:', data.user_image_url || '']); 
        return rows;
    };

    const buildCsvPlanimetryProduct = (data) => {
        const taskTypeLabel = data.task_type === 'planimetry' ? 'Verificacion de Planimetria' : data.task_type;
        const description = data.task_data && typeof data.task_data === 'object' ? data.task_data.description : '';
        const productNames = data.task_data && Array.isArray(data.task_data.product_names)
        ? data.task_data.product_names.join(', ')
        : '';
        const imageUrl = data.task_data && typeof data.task_data === 'object' && data.task_data.image_url
        ? data.task_data.image_url
        : 'No se ha agregado ninguna imagen';
        let rows = [];
        rows.push(['Reporte:', `Reporte detalles de tarea`]);
        rows.push(['Tipo de tarea:', taskTypeLabel]);
        rows.push(['Código de tarea:', data.uid || '']); 
        rows.push(['Cliente:', `${data.client_name || ''} - ${data.client_db_ref || ''}`]);
        rows.push(['Descripción:', description]);
        rows.push(['Cantidad de subtareas:', data.task_quantity || '']); 
        rows.push(['Dentro de Geocerca:',effectiveLocationWithAllowedLocation(data.effective_location, data.has_allowed_location)]); 
        rows.push(['']);
        rows.push(['Codigo de usuario:', data.user_uid || '']); 
        rows.push(['Usuario:', data.user_full_name || '']); 
        rows.push(['Fecha:', formatDate(data.created_date)]);
        rows.push(['']);
        rows.push(['PRODUCTOS:', 'PRODUCTOS:', productNames]);
        if (data.planimetry_fulfilled) {
            rows.push(['CUMPLE PLANIMETRIA:', 'Sí']);
        } else {
            rows.push(['CUMPLE PLANIMETRIA:', 'NO', 'MOTIVO:' + ' ' + (data.comments || '')]);
        }
        rows.push(['IMAGEN DE REFERENCIA: ', imageUrl]); 
        rows.push(['FOTO DE EVIDENCIA:', data.user_image_url || '']); 
        return rows;
    };


    const buildCsvSurvey = (data, answer , images) => {
        const taskTypeLabel = data.task_type === 'survey' ? 'Encuesta' : data.task_type;
        const description = data.task_data && typeof data.task_data === 'object' ? data.task_data.description : '';

        const dataWithAnswers = data.task_data.surveys.map(item => {
            const foundAnswer = answer.find(ans => ans.question === item.question);
            if (foundAnswer) {
              if (item.type === 'image' && images) {
                item.response = images;
              }else if (item.type === 'multiplechk') {
                const answerOptions = foundAnswer.answer.split(',').map(opt => opt.trim());
                item.options.forEach(option => {
                  option.selected = answerOptions.includes(option.text);
                });
              } else if (item.type === 'multiplerb') {
                item.options.forEach(option => {
                  option.selected = option.text === foundAnswer.answer;
                });
              } else {
                item.response = foundAnswer.answer;
              }
            }
            return item;
          });

          
        let rows = [];
        rows.push(['Reporte:', `Reporte detalles de tarea`]);
        rows.push(['Tipo de tarea:', taskTypeLabel]);
        rows.push(['Código de tarea:', data.uid || '']); 
        rows.push(['Cliente:', `${data.client_name || ''} - ${data.client_db_ref || ''}`]);
        rows.push(['Descripción:', data.task_description || '']);
        rows.push(['Cantidad de subtareas:', data.task_quantity || '']); 
        rows.push(['Dentro de Geocerca:',effectiveLocationWithAllowedLocation(data.effective_location, data.has_allowed_location)]); 
        rows.push(['']);
        rows.push(['Codigo de usuario:', data.user_uid || '']); 
        rows.push(['Usuario:', data.user_full_name || '']); 
        rows.push(['Fecha:', formatDate(data.created_date)]);
        rows.push(['']);
        dataWithAnswers.forEach((survey, index) => {
            const questionRow = [`PREGUNTA ${index + 1}: ${survey.question}`];
            if (survey.type === 'multiplechk') {
                let selectedOptions = survey.options
                    .filter(option => option.selected)
                    .map(option => option.text);
        
                if (selectedOptions.length === 0) {
                    questionRow.push('No hay respuestas seleccionadas');
                } else {
                    questionRow.push(...selectedOptions);
                }
            } 
            else if (survey.type === 'multiplerb') {
                let selectedOptions = survey.options
                    .filter(option => option.selected)
                    .map(option => option.text);
        
                if (selectedOptions.length === 0) {
                    questionRow.push('No hay respuestas seleccionadas');
                } else {
                    questionRow.push(...selectedOptions);
                }
            } 
            
            else {
                questionRow.push(survey.response || 'Sin respuesta');
            }
        
            rows.push(questionRow);
        });

        return rows;
    };


    const buildCsvInventory = (data) => {
        const taskTypeLabel = data.task_type === 'inventory' ? 'Captura de Inventario' : data.task_type;
        const description = data.task_data && typeof data.task_data === 'object' ? data.task_data.description : '';
        const productNames = data.task_data && Array.isArray(data.task_data.product_names)
        ? data.task_data.product_names.join(', ')
        : '';
        const imageUrl = data.task_data && typeof data.task_data === 'object' && data.task_data.image_url
        ? data.task_data.image_url
        : 'No se ha agregado ninguna imagen';
        let rows = [];
        rows.push(['Reporte:', `Reporte detalles de tarea`]);
        rows.push(['Tipo de tarea:', taskTypeLabel]);
        rows.push(['Código de tarea:', data.uid || '']); 
        rows.push(['Cliente:', `${data.client_name || ''} - ${data.client_db_ref || ''}`]);
        rows.push(['Descripción:', description]);
        rows.push(['Cantidad de subtareas:', data.task_quantity || '']); 
        rows.push(['Dentro de Geocerca:',effectiveLocationWithAllowedLocation(data.effective_location, data.has_allowed_location)]); 
        rows.push(['']);
        rows.push(['Codigo de usuario:', data.user_uid || '']); 
        rows.push(['Usuario:', data.user_full_name || '']); 
        rows.push(['Fecha:', formatDate(data.created_date)]);
        rows.push(['']);

        rows.push(['CODIGO', 'CODIGO DE BARRA', 'NOMBRE DEL PRODUCTO', 'EMPAQUE', 'INVENTARIO']);
        if (Array.isArray(data.details) && data.details.length > 0) {
            data.details.forEach(detail => {
                rows.push([
                    detail.product_db_ref || 'empty',
                    detail.product_barcode || detail.product_db_ref,
                    detail.product_name || 'empty',
                    detail.package_unit || 'empty',
                    detail.inventory_quantity !== undefined ? detail.inventory_quantity : 'empty'
                ]);
            });
        } else {
            rows.push(['empty', 'empty', 'empty', 'empty', 'empty']);
        }
        
        return rows;
    };

    const csvData = buildCsvData(data, answer , images);

    const filename = `ReporteDe_${typetask}_${data.user_full_name}_${getCurrentDate()}.csv`;
    const csvReport = {
        data: csvData,
        filename: filename
    };



//AutoDownload NO-CSVLINK
    const csvLinkRef = useRef(); 

    useEffect(() => {
        if (shouldDownload && data) {
          const csvContent = arrayToCsv(csvData);
          const csvUrl = downloadCsv(csvContent);
          const filename = `ReporteDe_${typetask}_${data.user_full_name}_${getCurrentDate()}.csv`;
          triggerDownload(csvUrl, filename);
          setShouldDownload(false);
        }
      }, [shouldDownload, data, csvData, typetask]);
      
      
      const arrayToCsv = (data) => {
        const csvRows = [];
              for (const row of data) {
          const values = row.map(value => {
            const stringValue = (value === undefined || value === null) ? '' : value.toString();
            const escaped = stringValue.replace(/"/g, '\\"');
            return `"${escaped}"`;
          });
          csvRows.push(values.join(','));
        }
      
        return csvRows.join('\n');
      };
      

  const BOM = '\uFEFF';

  
const downloadCsv = (csvData) => {
  const blob = new Blob([BOM + csvData], { type: 'text/csv;charset=utf-8;' });
  const url = window.URL.createObjectURL(blob);
  return url;
};

  const triggerDownload = (url, filename) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  

    return (
        <>
        { reportDownloads && (
        <CSVLink
            className="btn btn-default download-report-button-settlements"
            {...csvReport}
        >
            <FontAwesomeIcon icon={faArrowCircleDown} />
            Descargar Reporte
        </CSVLink>
        )}
        </>
    );
}