import React, { useState, useEffect } from 'react'
import cookie from 'react-cookies'
import NavBar from '../layout/NavBar'
import TopBar from '../layout/TopBar'
import ProductIndexView from './ProductIndexView'
import ProductContext from './context/ProductContext'

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const ProductIndexApp = () => {
  const initPaginationData = () => {
    const searchParams = new URLSearchParams(window.location.search)
    let page = searchParams.get('page')
    let currentPage = 1
    if (page) {
      currentPage = Number(page)
    } else {
      searchParams.set('page', '1')
      let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString()
      window.history.pushState(null, '', newRelativePathQuery)
    }
    return {
      per_page: 25,
      current_page: currentPage,
      total_pages: 1,
      searchInput: ''
    }
  }

  const [userName, setUserName] = useState('Ana Sofia Lau Hou')
  const [perPage, setPerPage] = useState(25)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [isFetching, setIsFetching] = useState(true)
  const [data, setData] = useState(null)
  const [clientsData, setClientsData] = useState(null)
  const [title, setTitle] = useState('')
  const [query, setQuery] = useState('')
  const [collapsed, setCollapsed] = useState(false)
  const [brands, setBrands] = useState([])
  const [categories, setCategories] = useState([])
  const [fetchDataStatus, setFetchDataStatus] = useState({ filters: false })
  const [filterQuery, setFilterQuery] = useState('')
  const [pagination, setPagination] = useState(initPaginationData())
  const [labelToIdMapping, setLabelToIdMapping] = useState({})
  const [allSelectedOptions, setAllSelectedOptions] = useState(new Map())
  const [searchInput, setSearchInput] = useState('')
  const [filterVersion, setFilterVersion] = useState(0)
  const [triggerSearch, setTriggerSearch] = useState(false)
  const [byBrand, setByBrand] = useState('')
  const [brandName, setBrandName] = useState('')
  const [byCategory, setByCategory] = useState('')
  const [categoryName, setCategoryName] = useState('')
  const [filters, setFilters] = useState(new Map())
  const [initialDataLoaded, setInitialDataLoaded] = useState(false)

  ////// Stops fetchs when page is changed
  const abortController = new AbortController()
  const abortCurrentFetchs = () => {
    abortController.abort();
  };
  window.addEventListener("beforeunload", abortCurrentFetchs);
  //////

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    setByBrand(urlParams.get('by_brand') || '')
    setBrandName(urlParams.get('brand_name') || '')
    setByCategory(urlParams.get('by_category') || '')
    setCategoryName(urlParams.get('category_name') || '')
  }, [])

  const prepareFilterData = () => {
    let localLabelToIdMapping = {}
    let uniqueDescriptions = new Set()

    const brandOptions =
      brands
        ?.filter((brand) => {
          if (!uniqueDescriptions.has(brand.description)) {
            uniqueDescriptions.add(brand.description)
            localLabelToIdMapping[brand.description] = brand.id
            return true
          }
          return false
        })
        .map((brand) => ({ label: brand.description, value: brand.id })) || []

    uniqueDescriptions.clear()

    const categoryOptions =
      categories
        ?.filter((category) => {
          if (!uniqueDescriptions.has(category.description)) {
            uniqueDescriptions.add(category.description)
            localLabelToIdMapping[category.description] = category.id
            return true
          }
          return false
        })
        .map((category) => ({ label: category.description, value: category.id })) || []

    setLabelToIdMapping(localLabelToIdMapping)

    const newFilters = [
      { key: 'Marca', value: brandOptions.map((option) => option.label) },
      { key: 'Categoría', value: categoryOptions.map((option) => option.label) }
    ]

    setFetchDataStatus({ ...fetchDataStatus, filters: true })
    setFilters(newFilters)
  }

  const updateAllSelectedOptions = (identifier, selectedLabels) => {
    setAllSelectedOptions((prevOptions) => {
      const updatedOptions = new Map(prevOptions)
      const labels = selectedLabels || []
      const selectedIds = labels.map((label) => labelToIdMapping[label])
      updatedOptions.set(identifier, selectedIds)
      return updatedOptions
    })
    pagination.current_page = 1
    buildFiltersQuery()
  }

  const buildFiltersQuery = () => {
    let queryParam = ''
    for (const [key, value] of allSelectedOptions) {
      if (value.length !== 0) {
        if (key === 'Marca') {
          queryParam += `&brands=${value.join(',')}`
        } else if (key === 'Categoría') {
          queryParam += `&category=${value.join(',')}`
        }
      }
    }
    setFilterQuery(queryParam)
  }

  const resetReactFilters = () => {
    setAllSelectedOptions(new Map())
    setFilterQuery('')
    setSearchInput('')
    setFilterVersion((prevVersion) => prevVersion + 1)
    setTriggerSearch((prev) => !prev)
    setPagination((prevPagination) => ({
      ...prevPagination,
      current_page: 1,
      per_page: prevPagination.per_page
    }))
  }

  const handleToggleCollapse = (newCollapsed) => {
    setCollapsed(newCollapsed)
    localStorage.setItem('collapsedState', newCollapsed)
  }

  useEffect(() => {
    const collapsedFromStorage = localStorage.getItem('collapsedState') === 'true'
    setCollapsed(collapsedFromStorage)
  }, [])

  useEffect(() => {
    prepareFilterData()
  }, [brands, categories])

  useEffect(() => {
    initPaginationData()
    async function fetchAll() {
      await fetchClientsFromServer()
    }
    fetchAll()
  }, [])

  useEffect(() => {
    updateURLPaginationData()
    async function fetchAll() {
      await fetchProductsFromServer()
      setInitialDataLoaded(true)
    }
    fetchAll()
  }, [pagination.current_page, triggerSearch, filterQuery])

  useEffect(() => {
    if (initialDataLoaded && (byBrand || byCategory || brandName || categoryName)) {
      fetchProductsFromServer()
      pagination.current_page = 1
    }
  }, [byBrand, byCategory, brandName, categoryName, initialDataLoaded])

  useEffect(() => {
    if (allSelectedOptions.size > 0) {
      buildFiltersQuery()
    }
  }, [allSelectedOptions])

  const handlePaginationClick = (event) => {
    // <a> = when pagination elements were clicked
    if (event.target.tagName === 'A') {
      handlePagination(event.target)
    } else if (event.target.tagName === 'svg') {
      handlePagination(event.target.parentNode)
    } else if (event.target.tagName === 'path') {
      handlePagination(event.target.parentNode.parentNode)
    }
  }

  const handlePagination = (target) => {
    let targetId = target.id
    switch (targetId) {
      case 'first-page':
        if (pagination.current_page !== 1) {
          setPagination({ ...pagination, current_page: 1 })
        }
        break
      case 'last-page':
        if (pagination.current_page !== pagination.total_pages) {
          setPagination({ ...pagination, current_page: pagination.total_pages })
        }
        break
      case 'previous-page':
        if (pagination.current_page !== 1) {
          setPagination({ ...pagination, current_page: pagination.current_page - 1 })
        }
        break
      case 'next-page':
        if (pagination.current_page !== pagination.total_pages) {
          setPagination({ ...pagination, current_page: pagination.current_page + 1 })
        }
        break
    }
  }

  const isAnyFilterSelected = () => {
    return Array.from(allSelectedOptions.values()).some((options) => options.length > 0)
  }

  const updateURLPaginationData = () => {
    const searchParams = new URLSearchParams(window.location.search)
    searchParams.set('page', pagination.current_page)
    let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString()
    window.history.pushState(null, '', newRelativePathQuery)
  }

  const fetchClientsFromServer = async (loadingState = true) => {
    if (loadingState) {
      setFetchDataStatus({ ...fetchDataStatus, filters: true })
    }
    const per_page = perPage
    const page = currentPage
    try {
      const response = await fetch(
        BACKEND_URL +
          '/businesses/1/products' +
          '?page=' +
          page +
          '&per_page=' +
          per_page +
          '&brand_names_only=true&business_tenant_uid=' +
          cookie.load('business_tenant_uid'),
        {
          method: 'GET',
          signal: abortController.signal,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + cookie.load('token')
          }
        }
      )
      if (response.status === 401) {
        console.error('Authorization error', response)
        return
      }
      if (!response.ok) {
        console.error('HTTP error', response)
        return
      }
      const data = await response.json()
      setBrands(data.table.brands)
      setCategories(data.table.categories)
    } catch (error) {
      console.error('Fetch error:', error)
    } finally {
      setFetchDataStatus({ ...fetchDataStatus, filters: false })
    }
  }

  const fetchProductsFromServer = async () => {
    const per_page = pagination.per_page
    const page = pagination.current_page
    const response = await fetch(
      BACKEND_URL +
        '/businesses/1/products' +
        '?page=' +
        page +
        '&per_page=' +
        per_page +
        (filterQuery ? '&filter_search=true' : '') +
        (filterQuery ? filterQuery : '') +
        (searchInput ? '&q=' + searchInput : '') +
        (byBrand ? `&by_brand=${byBrand}` : '') +
        (byCategory ? `&by_category=${byCategory}` : ''),
      {
        method: 'GET',
        signal: abortController.signal,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + cookie.load('token')
        }
      }
    )

    if (response.status === 401) {
      console.error(response)
      return
    }

    const data = await response.json()
    let title = 'Productos'
    if (searchInput) {
      title = `Resultados de la búsqueda: ${searchInput}`
    }
    if (byBrand) {
      title = `Productos: Marca - ${brandName}`
    }
    if (byCategory) {
      title = `Productos: Categoría - ${categoryName}`
    }

    setPagination({ ...pagination, total_pages: Math.ceil(data['count'] / per_page) })
    setData(data['table'])
    setIsFetching(false)
    setTitle(title)
  }

  const handleSearchKeyPress = (event) => {
    if (event.key === 'Enter') {
      setTriggerSearch((prev) => !prev) // Toggle para asegurar un cambio de estado
    }
  }

  const handleSearch = (e) => {
    setSearchInput(e.target.value)
    pagination.current_page = 1
  }

  const handleEnterSearch = (e) => {
    if (e.key === 'Enter') {
      pagination.current_page = 1
      fetchProductsFromServer()
    }
  }

  return (
    <div>
      <div>
        <div>
          <TopBar
            searchPlaceholder={''}
            userName={'soporte'}
            onToggleCollapse={handleToggleCollapse}
            collapsed={collapsed}
          />
        </div>
        <div>
          <div>
            <ProductIndexView
              collapsed={collapsed}
              fetchDataStatus={fetchDataStatus}
              clientsData={clientsData}
              productData={data}
              total_pages={totalPages}
              current_page={currentPage}
              setFilterQuery={setFilterQuery}
              filterQuery={filterQuery}
              searchQuery={query}
              fetchClientsFromServer={fetchClientsFromServer}
              pagination={pagination}
              handlePaginationClick={handlePaginationClick}
              filters={filters}
              updateAllSelectedOptions={updateAllSelectedOptions}
              resetReactFilters={resetReactFilters}
              isAnyFilterSelected={isAnyFilterSelected()}
              onSearch={resetReactFilters}
              handleSearchKeyPress={handleSearchKeyPress}
              isFetching={isFetching}
              title={title}
              searchInput={searchInput}
              handleSearch={handleSearch}
              handleEnterSearch={handleEnterSearch}
              filterVersion={filterVersion}
              productFilter={{
                byBrand,
                brandName,
                byCategory,
                categoryName
              }}
            />
          </div>
        </div>
      </div>
      <div>
        <NavBar activeItem="Productos" isEditing={false} collapsed={collapsed} />
      </div>
    </div>
  )
}
export default ProductIndexApp
