import React from 'react'
import { effectiveLocationWithAllowedLocation, replaceWithBr } from '../../helpers/utils'
import { TASK_TYPE_DIC } from '../../pos/constants/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faArrowCircleDown } from '@fortawesome/free-solid-svg-icons'
import ImageModal from '../../components/image_modal/ImageModal'
import PosTaskSurvey from './PosTaskSurvey'
import { CSVLink } from 'react-csv'
import PosTaskReport from './PosTaskReport'

const LIMIT_PRODUCTS = 350
const LIMIT_INSTRUCTIONS = 250
export default class PosTaskDetailsTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activity: this.props.activity,
      displayMoreInstructions: false,
      displayMoreProducts: false,
      showImage: false,
      imageUrl: ''
    }
  }

  isInventoryTask = () => {
    return this.state.activity['task_type'] === 'inventory'
  }

  isPlanimetryTask = () => {
    return this.state.activity['task_type'] === 'planimetry'
  }
  isSurveyTask = () => {
    return this.state.activity['task_type'] === 'survey'
  }

  handleMoreInstructions = () => {
    this.setState({ displayMoreInstructions: !this.state.displayMoreInstructions })
  }

  handleMoreProducts = () => {
    this.setState({ displayMoreProducts: !this.state.displayMoreProducts })
  }

  showMoreInstructionsBtn = () => {
    return this.state.displayMoreInstructions ? 'Ver menos' : 'Ver más...'
  }

  showMoreProductsBtn = () => {
    return this.state.displayMoreProducts ? 'Ver menos' : 'Ver más...'
  }

  trimInstructionsString = (str) => {
    if (!str) return 'No se han agregado instrucciones'
    if (this.state.displayMoreInstructions) return str

    // check lines first
    const lines = str.split('\n')
    if (lines.length > 4) {
      return lines.slice(0, 4).join('\n')
    }

    if (str?.length > LIMIT_INSTRUCTIONS) {
      return str.substring(0, LIMIT_INSTRUCTIONS)
    }
    return str
  }

  displayProducts = (taskData) => {
    let selection = taskData?.products_selection_type
    let value = 'No se han agregado productos'
    switch (selection) {
      case 'individual_products': {
        value = this.trimProductsString(taskData?.product_names)
      }
      case 'category': {
        if (taskData?.category_name) {
          value = 'Categoría: ' + taskData?.category_name
        }
      }
      case 'brand': {
        if (taskData?.brand_name) {
          value = 'Marca: ' + taskData?.brand_name
        }
      }
    }
    return value
  }

  trimProductsString = (strArray) => {
    if (!strArray) return 'No se han agregado productos'
    let str = strArray?.join(', ')
    if (this.state.displayMoreProducts) return str
    if (str?.length > LIMIT_PRODUCTS) {
      return str.substring(0, LIMIT_PRODUCTS)
    }
    return str
  }

  strToMultiLine = (str) => {
    str.split('\n').map((item, key) => {
      return (
        <span key={key}>
          {item}
          <br />
        </span>
      )
    })
  }

  handleCloseImage = () => {
    this.setState({ showImage: false })
  }

  handleShowImage = (value) => {
    this.setState({ showImage: value })
  }

  handleUrlImage = (url) => {
    this.setState({ imageUrl: url, showImage: true })
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ activity: nextProps.activity })
  }

  getFormattedDate = (createdDate) => {
    if (!createdDate) return 'N/A'
    const d = new Date(createdDate)
    return d.toLocaleString()
  }

  getFormattedDateOnly = (createdDate) => {
    if (!createdDate) return 'N/A'
    const d = new Date(createdDate)
    return d.toLocaleDateString()
  }

  calculatePaymentsTotal = () => {
    let total = 0
    this.state.activity?.payment_methods?.map((obj) => (total += parseFloat(obj.amount)))
    return total
  }

  getWeight = (_weight = 0) => {
    let weight = Number(_weight).toFixed(2)
    if (weight == 0) return 'N/A'
    return weight
  }

  getTotalDiscount = () => {
    let discountTotal = 0
    this.state.activity.details.map((obj) => {
      const discountPrice = obj.discount_price ? obj.discount_price : 0
      return (discountTotal += parseFloat(discountPrice))
    })
    return discountTotal
  }

  getTotalWeight = () => {
    let tw = Number(this.state.activity['total_weight']).toFixed(2)
    if (tw == 0) return 'N/A'
    return tw
  }

  handleSendData = (data) => {
    this.setState({ reportData: this.state })
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <>
            <div className="row show-product-header">
              <div className="col-sm-6">
                <h3 className="fs-18">
                  <strong>Detalles de la tarea:</strong>
                </h3>
              </div>
              <div className="col-sm-6 text-right">
                {
                  <PosTaskReport
                    data={this.state.activity}
                    answer={this.state.activity.details_survey}
                    images={this.state.activity.user_image_url}
                    reportDownloads={true}
                  />
                }
              </div>
            </div>
            <hr />
          </>
          <div>
            <div className="show-product-header-ubiqua-app show-header">
              <div>
                <div className="order-client-name">{this.state.activity['client_name']}</div>
                <div className="order-client-db_ref">
                  Código: {this.state.activity['client_db_ref']}
                </div>
              </div>
              {/* {this.state.activity?.origin == 'UbiquaApp' && (
                <button className="float-right download-order-button" onClick={this.download}>
                  Descargar reporte
                  <FontAwesomeIcon icon={faArrowCircleDown} />
                </button>
              )} */}
            </div>
            <hr />
            <div>
              <table cellSpacing="10" style={{ maxWidth: '80%' }}>
                <tbody>
                  <tr>
                    <td>
                      <div className="mr-20 mb-10">
                        <b>Fecha de ejecución:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-10">
                        {this.getFormattedDate(this.state.activity['created_date'])}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-20 mb-10">
                        <b>Tipo de tarea:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-10">{TASK_TYPE_DIC[this.state.activity['task_type']]}</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-20 mb-10">
                        <b>Descripción:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-10">{this.state.activity['task_description']}</div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="mr-20 mb-10">
                        <b>Cantidad de subtareas:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-10">{this.state.activity['task_quantity']}</div>
                    </td>
                  </tr>
                  {this.isPlanimetryTask() && (
                    <>
                      <tr>
                        <td>
                          <div className="mr-20 mb-10">
                            <b>Instrucciones:</b>
                          </div>
                        </td>
                        <td>
                          <div className="mb-10">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: replaceWithBr(
                                  this.trimInstructionsString(
                                    this.state.activity?.task_data?.instructions
                                  )
                                )
                              }}
                            ></span>
                            {this.state.activity?.task_data?.instructions.length >
                            LIMIT_INSTRUCTIONS ? (
                              <span onClick={this.handleMoreInstructions} className="ubiqua-link">
                                {' '}
                                {this.showMoreInstructionsBtn()}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="mr-20 mb-10">
                            <b>Productos:</b>
                          </div>
                        </td>
                        <td>
                          <div className="mb-10">
                            {this.displayProducts(this.state.activity?.task_data)}{' '}
                            {this.state.activity?.task_data?.product_names?.join(', ').length >
                              LIMIT_PRODUCTS && (
                              <span onClick={this.handleMoreProducts} className="ubiqua-link">
                                {' '}
                                {this.showMoreProductsBtn()}
                              </span>
                            )}
                          </div>
                        </td>
                      </tr>
                    </>
                  )}
                  <tr>
                    <td>
                      <div className="mr-20 mb-10">
                        <b>Dentro de geocerca:</b>
                      </div>
                    </td>
                    <td>
                      <div className="mb-10">
                        {effectiveLocationWithAllowedLocation(
                          this.state.activity['effective_location'],
                          this.state.activity['has_allowed_location']
                        )}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr />
              <div className="mb-20">
                <h4>Detalles de reporte:</h4>
              </div>
              {this.isSurveyTask() && (
                <div>
                  <PosTaskSurvey
                    data={this.state.activity.task_data.surveys}
                    answer={this.state.activity.details_survey}
                    images={this.state.activity.user_image_url}
                  />
                </div>
              )}
              {this.isInventoryTask() && (
                <table className="table table-hover mb-0">
                  <thead>
                    <tr>
                      <th>CÓDIGO</th>
                      <th>COD. DE BARRA</th>
                      <th>NOMBRE DEL PRODUCTO</th>
                      <th>EMPAQUE</th>
                      <th className="text-center">INVENTARIO</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.activity['details'].map((model, index) => (
                      <tr key={index}>
                        <td>{model['product_db_ref']}</td>
                        <td>{model['product_db_ref']}</td>
                        <td>{model['product_name']}</td>
                        <td>{model['package_unit']}</td>
                        <td className="text-center">{model['inventory_quantity']}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}

              {this.isPlanimetryTask() && (
                <div>
                  <table cellSpacing="10" style={{ maxWidth: '80%', marginBottom: '20px' }}>
                    <tbody>
                      <tr>
                        <td>
                          <div className="mr-20 mb-10">
                            <b>¿Cumple planimetría?</b>
                          </div>
                        </td>
                        <td>
                          <div className="mb-10">
                            {this.state.activity['planimetry_fulfilled'] ? (
                              <p className="mb-0 green-box-text">Si</p>
                            ) : (
                              <p className="mb-0 red-box-text">No</p>
                            )}
                          </div>
                        </td>
                      </tr>
                      {!this.state.activity['planimetry_fulfilled'] && (
                        <tr>
                          <td>
                            <div className="mr-20 mb-10">
                              <b>Motivo:</b>
                            </div>
                          </td>
                          <td>
                            <div className="mb-20">{this.state.activity['comments']}</div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>

                  <div className="planimetry-images-container">
                    <div className="planimetry-image-report shadow">
                      <strong className="mb-10">Imagen de referencia:</strong>
                      {this.state.activity?.task_data?.image_url && (
                        <img
                          onClick={() =>
                            this.handleUrlImage(this.state.activity?.task_data?.image_url)
                          }
                          className="pos-images"
                          src={this.state.activity?.task_data?.image_url}
                        />
                      )}
                      {!this.state.activity?.task_data?.image_url && (
                        <div className="no-image-container">
                          <img src="/assets/no-image.svg" />
                          <strong className="mt-10">No se ha agregado ninguna imagen</strong>
                        </div>
                      )}
                    </div>
                    <div className="planimetry-image-report shadow">
                      {this.state.activity?.user_image_url && (
                        <a
                          className="float-btn"
                          href={this.state.activity?.user_image_url}
                          download
                          target="_blank"
                        >
                          Descargar imagen <FontAwesomeIcon icon={faDownload} />{' '}
                        </a>
                      )}
                      <strong className="mb-10">Foto de evidencia:</strong>
                      <img
                        onClick={() => this.handleUrlImage(this.state.activity?.user_image_url)}
                        className="pos-images"
                        src={this.state.activity?.user_image_url}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <ImageModal
          imgSrc={this.state?.imageUrl}
          showImage={this.state.showImage}
          setShowImage={this.handleShowImage}
        />
      </div>
    )
  }
}
