import React, { useContext, useState } from 'react'
import GoogleMapReact from 'google-map-react'
import MapPoint from '../../map/MapPoint'
import { MapModalStore } from '../../hook/MapModalProvider'

const API_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY || 'AIzaSyAxo7saU4Ewn8PSMh8Nk4Y_m5vvC1LbrAQ'

const ShowMapModalTimestampContent = (props) => {

  const [mapApiLoaded, setMapApiLoaded] = useState(false)
  const [mapInstance, setMapInstance] = useState(null)
  const [mapApi, setMapApi] = useState(null)
  const [bounds, setBounds] = useState(null)
  const [zoom, setZoom] = useState(10)

  const [startLatitude] = useState(props.startLatitude)
  const [startLongitude] = useState(props.startLongitude)
  const [endLatitude] = useState(props.endLatitude)
  const [endLongitude] = useState(props.endLongitude)
  const [clientLatitude] = useState(props.clientLatitude)
  const [clientLongitude] = useState(props.clientLongitude)

  const { modalMapStyles } = useContext(MapModalStore)

  const startCenter = {
    lat: parseFloat(startLatitude) || 8.986984,
    lng: parseFloat(startLongitude) || -79.518519
  }

  const centCircle = {
    lat: parseFloat(clientLatitude) || 8.986984,
    lng: parseFloat(clientLongitude) || -79.518519
  }

  // Re-center map when resizing the window
  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(window, 'resize', () => {
        map.fitBounds(bounds)
      })
    })
  }

  // Return map bounds based on list of places
  const getMapBounds = (map, maps, places) => {
    const bounds = new maps.LatLngBounds()
    places.forEach((place) => {
      bounds.extend(new maps.LatLng(place.latitude, place.longitude))
    })
    return bounds
  }

  // Fit map to its bounds after the api is loaded
  const apiIsLoaded = (map, maps, places) => {
    // Get bounds by our places
    {
      parseInt(clientLatitude) !== 0 &&
        new maps.Circle({
          strokeColor: '#7F7F7F',
          strokeOpacity: 0.4,
          strokeWeight: 2,
          fillColor: '#7F7F7F',
          fillOpacity: 0.3,
          map,
          center: centCircle,
          radius: 100
        })
    }

    let place = []
    if (parseInt(clientLatitude) !== 0 && parseInt(endLatitude) !== 0 ) {
      place = [
        {
          latitude: startLatitude,
          longitude: startLongitude
        },
        {
          latitude: clientLatitude,
          longitude: clientLongitude
        },
        {
          latitude: endLatitude,
          longitude: endLongitude
        }
      ]
    } else if (parseInt(clientLatitude) !== 0){
      place = [
        {
          latitude: startLatitude,
          longitude: startLongitude
        },
        {
          latitude: clientLatitude,
          longitude: clientLongitude
        }
      ]
    } else {
      place = [
        {
          latitude: startLatitude,
          longitude: startLongitude
        }
      ]
    }

    const bounds = getMapBounds(map, maps, place)
    // Fit map to bounds
    map.fitBounds(bounds)
    // Bind the resize listener
    bindResizeListener(map, maps, bounds)
  }

  const apiHasLoaded = (map, maps) => {
    setMapInstance(map)
    setMapApi(maps)
    setMapApiLoaded(true)
  }

  const setZoomProvider = (zoom) => {
    setZoom(zoom)
  }

  const setBoundsProvider = (bounds) => {
    setBounds(bounds)
  }

  return (
    <>
      {parseInt(startLatitude) !== 0 && parseInt(startLongitude) !== 0  && (
        <GoogleMapReact
          bootstrapURLKeys={{
            key: API_KEY
          }}
          onGoogleApiLoaded={({ map, maps }) => {
            map.setOptions({
              maxZoom: 18
            })
            apiHasLoaded(map, maps)
            apiIsLoaded(map, maps)
          }}
          defaultCenter={startCenter}
          defaultZoom={18}
          layerTypes={[]}
          center={mapApiLoaded ? mapInstance.getCenter().toJSON() : [1, 2]}
          options={{ styles: modalMapStyles }}
          onChange={({ zoom, bounds }) => {
            setZoomProvider(zoom)
            setBoundsProvider([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat])
          }}
        >
          <MapPoint
            lat={startLatitude}
            lng={startLongitude}
            name={"Hora de inicio - " + props.salesmanVisitData.start_visit_time} 
            isReport={false}
            color="blue"
          />
          {parseInt(clientLatitude) !== 0 && (
            <MapPoint
              lat={clientLatitude}
              lng={clientLongitude}
              isReport={true}
              name="Ubicación del cliente"
              color="green"
            />
          )}
          {parseInt(endLatitude) !== 0 && (
            <MapPoint
                lat={endLatitude}
                lng={endLongitude}
                name={"Hora de finalizacion - " + props.salesmanVisitData.end_visit_time} 
                isReport={false}
                color="red"
            />
          )}
        </GoogleMapReact>
      )}
        {parseInt(startLatitude) == 0 && parseInt(startLongitude) == 0  && (
          <div className="map-modal-error">
              <div className="map-error-label">
              La geocoordenada no ha sido capturada. El usuario ha denegado el acceso a la ubicación del equipo. Debe solicitar al usuario que habilite el acceso.
              </div>
          </div>
        )}
    </>
  )
}

export default ShowMapModalTimestampContent